.services__blog {
  display: flex;
  flex-direction: column;
}

.services__blog-heading {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-right: 5rem;
  margin-bottom: 5rem;
}

.services__blog-heading h1 {
  font-size: 36px;
  line-height: 75px;
  font-family: var(--font-family);
  font-weight: 600;
}
.services__blog-heading p {
  font-size: 18px;
  font-family: var(--font-family2);
  font-weight: 200;
  line-height: 30px;
  margin-top: 2rem;
}

.services__blog-container {
  display: flex;
  flex-direction: row;
}

.service__reloc-content {
  display: flex;
  flex-direction: row;
}

.service__reloc-content h1 {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 36px;
  line-height: 45px;
  margin: 1rem 0;
  color: var(--color-head);
}

.service__reloc-content p {
  color: var(--color-text);
  font-family: var(--font-family2);
  font-weight: 200;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 2rem;
}

.reloc__possibility-image {
  flex: 1;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  max-width: 30rem;
  padding: 4rem;
}
.reloc__possibility-image img {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 1050px) {
  .service__reloc-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width: 700px) {
  .services__blog-heading p {
    font-size: 16px;
  }

  .service__reloc-content p {
    font-size: 16px;
  }
  .services__blog-heading h1 {
    font-size: 30px;
    line-height: 52px;
  }
  .service__reloc-content-heading {
    display: flex;
    flex-direction: column;
  }
  .services__left-text {
    margin: 2rem;
  }
  .reloc__possibility-image {
    padding: 0rem;
  }
  .service__reloc-content h1 {
    font-size: 30px;
  }
  .service__reloc-content {
    margin-top: 2rem;
  }
}
