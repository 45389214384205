.immi__navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 3rem 2rem 2rem;
  background-color: #ffffff;
  width: 100%;
  /* t r b l */
}

.links {
  transition: 0.5s all ease-in-out;

}

.links:hover {
  color: #ef0325 !important;
}

.link {
  color: black !important;
  transition: 0.5s all ease-in-out;
}

.link:hover {
  /* background-color: #060056 !important;
  color: white !important; */
  color: #ef0325 !important;
} 

.link-active {
  background-color: #060056 !important;
  padding: 10px;
  border-radius: 10px;
  color: white !important;
}


.immi__navbar-links {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.immi__navbar-links_logo img {
  width: 150.45px;
  height: 50.02px;
}
.immi__navbar-links_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1;
}
.immi__navbar-sign {
  display: flex;
  justify-content: flex;
  align-items: center;
}
.immi__navbar-links_container p,
.immi__navbar-sign p,
.immi__navbar-menu_container p {
  color: rgb(6, 4, 4);
  font-family: var(--font-family2);
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  text-transform: capitalize;
  margin: 0 1.5rem;
  cursor: pointer;
}
.immi__navbar-sign button,
.immi__navbar-menu_container button {
  padding: 0.5rem 1rem;
  background-color: #ffffff;
  color: #060056;
  font-size: 16px;
  line-height: 25px;
  font-family: var(--font-family2);
  font-weight: 500;
  border-radius: 20px;
  width: 120px;
  border: 0;
  outline: none;
  cursor: pointer;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.immi__navbar-menu {
  margin-left: 1rem;
  display: none;
  position: relative;
}



.immi__navbar-menu svg {
  cursor: pointer;
}

.img-container:hover {
  cursor: pointer;
}

.immi__navbar-menu_container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  text-align: end;
  background: #e8e6e6;
  padding: 2rem;
  position: absolute;
  top: 40px;
  z-index: 1;
  right: 0;
  margin-top: 1rem;
  min-width: 210px;
  border-radius: 5px;
  box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
}

.immi__navbar-menu_container p {
  margin: 1rem 0;
}
.immi__navbar-menu_container-links-sign {
  display: none;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  text-align: initial;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-submenu {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 2;
  left: 8rem;
}

.dropdown-submenu a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-submenu a:hover {
  background-color: #f1f1f1;
}

.ser2:hover .dropdown-submenu {
  display: block;
}

@media screen and (max-width: 1050px) {
  .immi__navbar-links_container {
    display: none;
  }
  .immi__navbar-menu {
    display: flex;
  }
  .dropdown-content {
    min-width: 120px;
  }

  .dropdown-content a {
    padding: 15px 15px;
  }
  .dropdown-submenu {
    min-width: 100px;
    left: 0rem;
  }

  .dropdown-submenu a {
    padding: 15px 15px;
    right: 8rem;
  }
}

@media screen and (max-width: 700px) {
  .immi__navbar {
    padding: 2rem 4rem;
  }
}

@media screen and (max-width: 550px) {
  .immi__navbar {
    padding: 2rem;
  }

  .immi__navbar-sign {
    display: none;
  }

  .immi__navbar-menu_container {
    top: 20px;
  }

  .immi__navbar-menu_container-links-sign {
    display: block;
  }
  .dropdown-content {
    min-width: 100px;
    left: 0rem;
  }

  .dropdown-content a {
    padding: 15px 15px;
  }
  .dropdown-submenu {
    min-width: 122px;
    left: -6rem;
  }

  .dropdown-submenu a {
    padding: 15px 15px;
    right: 8rem;
  }
}
