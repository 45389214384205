.uk__possibility {
  display: flex;
  flex-direction: row;
  margin-bottom: 5rem;
}
.uk__possibility-image {
  flex: 1;
  display: flex;
  margin-right: 6rem;
}
.uk__possibility-image img {
  width: 100%;
  height: 100%;
}
.uk__possibility-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.uk__possibility-content h1 {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 36px;
  line-height: 45px;
  margin: 1rem 0;
}

.uk__possibility-content p {
  color: var(--color-text);
  font-family: var(--font-family2);
  font-weight: 200;
  font-size: 18px;
  line-height: 30px;

  margin-bottom: 2rem;
}

/* Section Ability */
.uk__section-ability {
  display: flex;
  flex-direction: column;
  /* justify-content: flex-start;
  align-items: flex-start; */
}
.uk__section-ability-content {
  /* margin-top: 5rem; */
  margin-left: 2.5rem;
}
.uk__section-ability-content h1 {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 36px;
  line-height: 45px;
  margin: 1rem 0;
  margin-bottom: 2rem;
}
.uk__section-ability-content p {
  color: var(--color-text);
  font-family: var(--font-family2);
  font-weight: 200;
  font-size: 18px;
  line-height: 30px;

  margin-bottom: 2rem;
}

/* new container */
.uk-img-cont {
  padding: 2rem 4rem;
  justify-content: baseline;
}

.uk__section-logo-container {
  flex: 1;
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 2rem;
  align-items: baseline;
  grid-template-columns: repeat(3, 1fr);
}
.uk__section-logo-container-two {
  /* flex: 1;
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 2rem;
  align-items: baseline;
  grid-template-columns: repeat(2, 1fr); */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 150px;
}
.uk__section-help {
  flex: 1;
  display: flex;
  width: 100%;
  color: white;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
}
.uk-container-a {
  max-width: 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.uk-container-a p {
  color: black;
  font-family: var(--font-family2);
  font-weight: 200;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
}

/* section 3 */

/* section 4 */
.uk__section-helpp {
  /* background: #f9f9f9; */
  background-image: url(../../assets/Group_491.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom right;
  border-radius: 28px;
  box-shadow: 0px 0px 8px 0px lightgray;
  border: 20px;
  flex: 1;
  display: flex;
  padding: 2rem 5rem;
  margin-top: 2rem;
  margin-bottom: 5rem;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.uk__section-introo-content {
  text-align: left;
  max-width: 850px;
}
.uk__section-introo-content h1 {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 36px;
  line-height: 45px;
  margin: 1rem 0;
  margin-bottom: 2rem;
  color: var(--color-head);
}
.uk__section-introo-content p {
  color: black;
  font-family: var(--font-family2);
  font-weight: 200;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 2rem;
  text-align: justify;
}
.uk__section-introo-content button {
  background: #f9f9f9;
  border-radius: 40px;
  color: #060056;
  font-style: normal;
  font-size: 18px;
  line-height: 30px;
  padding: 0.5rem 1rem;
  border-style: solid;
  border-width: thin;
  font-weight: 500;
  font-family: var(--font-family2);
  cursor: pointer;
  min-width: 200px;
  margin-top: 2rem;
}
.need_immigration_for_uk_main_div {
  width: 100%;
  height: 250px;
  background-image: url(../../assets/group29.png);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 120px;
}
.need_immigration_for_uk_inner_div1 p {
  font-family: Orbitron;
  font-size: 40px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  text-align: left;
}
.need_immigration_for_uk_inner_div2 button {
  height: 70px;
  padding: 0px 70px 0px 70px;
  border-radius: 28px;
  background-color: #060056;
  font-family: Archivo;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: center;
  color: white;
}
.section__margin {
  margin: 3rem 10rem 10rem 10rem;
}
.uk_section_helpp_main_div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 1270px) {
  .uk__section-logo-container {
    flex-direction: column;
  }
  .need_immigration_for_uk_main_div {
    height: fit-content;
    flex-direction: column;
    padding: 4rem 0;
    gap: 50px;
  }
  .uk__section-logo-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .uk__section-logo-container-two {
    flex-direction: row;
  }
  .uk__section-ability-content {
    margin: 0;
    padding: 3rem;
    text-align: center;
  }
  .uk__section-ability-content h1 {
    width: 100%;
    margin: 0;
  }
  .uk__section-introo-content p {
    width: 100%;
    text-align: justify;
  }
  .uk__section-ability-content p {
    width: 100%;
  }
  .section__margin {
    margin: 3rem 5rem 10rem 5rem;
    padding: 2rem;
  }
}
@media screen and (max-width: 1050px) {
  .uk__possibility {
    flex-direction: column;
    margin-bottom: 0rem;
  }

  .uk__possibility-image {
    margin: 1rem 0;
  }

  .uk__possibility-content {
    margin-top: 2rem;
  }
}
@media screen and (max-width: 700px) {
  .uk__section-helpp{
    padding: 1rem 2rem;
  }
  .uk__possibility-image img {
    width: 100%;
    height: 100%;
  }
  .uk__section-ability-content p {
    font-size: 16px;
  }
  .uk__section-ability-content h1 {
    font-size: 30px;
  }
  .uk__section-logo-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .uk__section-logo-container-two {
    flex-direction: column;
    gap: 50px;
  }
  .uk__possibility-content p {
    font-size: 16px;
  }
  .uk__section-introo-content p {
    font-size: 16px;
  }
  .uk__section-introo-content h1 {
    width: 100%;
    font-size: 30px;
  }
  .need_immigration_for_uk_inner_div1 p {
    width: 100%;
    text-align: center;
    font-size: 30px;
  }
  .section__margin {
    margin: 3rem 2rem 10rem 2rem;
    padding: 1rem;
  }
}
