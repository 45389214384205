.fanuun__blog-container_article {
  /* width: 90% !important; */
  /* backgrou nd-color: #060056 !important; */
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #f9f9f9;
  border-radius: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 100%;
}

.fanuun__blog-container_article-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 300px;
  padding: 10px;
  padding-left: 16px;
}

.fanuun__blog-container_article-content-subb {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.numberrr {
  height: 50px;
  margin: 0;
}
.fanuun__blog-container_article-content h5 {
  font-family: var(--font-family2);
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  margin-top: 1rem;
  color: rgb(53, 52, 52);
  text-align: justify;
}

.fanuun__blog-container_article-content h3 {
  font-family: var(--font-family);
  font-size: 21px;
  font-weight: 600;
  margin-top: 5%;
  /* line-height: 30.3px; */
  color: #060056;
  cursor: pointer;
  margin-bottom: 0.5rem;
}

.fanuun__blog-container_article-content p:last-child {
  cursor: pointer;
  font-size: 40px;
  display: flex;
  justify-content: flex-end;
  color: rgb(208, 214, 214);
}

@media screen and (max-width: 550px) {
  .fanuun__blog-container_article-content h3 {
    font-size: 18px;
    line-height: 25p;
  }
}

.fanuun__blog-container_article-content p {
  font-family: var(--font-family);
  font-size: 11.65px;
  font-weight: 700;

  color: rgb(0, 0, 0);
}

.fanuun__blog-container_article-content p:last-child {
  cursor: pointer;
}

@media screen and (max-width: 550px) {
  .fanuun__blog-container_article-content h3 {
    font-size: 18px;
    line-height: 25p;
  }
}
