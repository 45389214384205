.uae__incorporation-main {
  max-width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 5%;
}

.uae__incorporation {
  display: flex;
  flex-direction: row;
  margin-bottom: 4rem;
  max-width: 75%;
  background: #f9f9f9;
  border-radius: 12px;
  padding-left: 2%;
  /* height: 300px; */
}

.uae__incorporation-image {
  max-width: 56%;
  display: flex;
  justify-content: flex-end;
  margin-left: 5%;
}

.uae__incorporation-image img {
  max-width: 100%;
  height: 100%;
}

.uae__incorporation-content {
  max-width: 44%;
  /* margin-left: 4%; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.uae__incorporation-content h1 {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 36px;
  line-height: 45px;
  margin: 1rem 0;
}

.uae__incorporation-content p {
  color: var(--color-text);
  font-family: var(--font-family2);
  font-weight: 200;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 2rem;
}

.uae__section-incorporation {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  align-items: center;
}

.uae__section-incorporation-content {
  max-width: 70%;
}

.uae__section-incorporation-content h1 {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 36px;
  line-height: 45px;
  margin: 1rem 0;
  margin-bottom: 2rem;
}

.uae__section-incorporation-content p {
  color: var(--color-text);
  font-family: var(--font-family2);
  font-weight: 200;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 2rem;
  width: 65%;
  text-align: justify;
}

.form__section-help-incorporation {
  /* flex: 1; */
  display: flex;
  width: 73%;
  color: white;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
}

.htag {
  background-image: url('../../assets/group29.png');
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.fanuun__cta-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  
}
.fanuun__cta-btn button {
  background: #060056;
  border-radius: 40px;
  color: white;
  font-style: normal;

  font-size: 16px;
  line-height: 30px;
  padding: 0.5rem 1rem;
  border: none;
  outline: none;
  font-family: var(--font-family2);
  cursor: pointer;
  min-width: 150px;
}
.htag-text {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 35px;
  font-family: var(--font-family);
  text-align: center;
  width: 75%;
}

.htag-button {
  background: #060056;
  border-radius: 40px;
  color: white;
  font-style: normal;
  font-size: 16px;
  line-height: 30px;
  padding: 0.5rem 3rem;
  border: none;
  outline: none;
  font-family: var(--font-family2);
  cursor: pointer;
  margin-top: 2rem;
  /* min-width: 150px; */
}



.form-container-a-incorporation p {
  color: black;
  font-family: var(--font-family2);
  font-weight: 200;
  font-size: 18px;
  line-height: 30px;
  text-align: left;
  margin-top: 1rem;
}

.form-container-a-incorporation h1 {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 20px;
  line-height: 45px;
  margin: 1rem 6px;
  color: #312b73;
}



.form-container-a-incorporation {
  max-width: 30rem;
  display: flex;
  flex-direction: column;
  border-left: 3px solid red;
  justify-content: center;
  /* padding: 1.5rem; */
  padding-left: 1.7rem;
}

.form-content-incorporation {
  width: 70%;
}

.form-content-incorporation p {
  color: var(--color-text);
  font-family: var(--font-family2);
  font-weight: 200;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 2rem;
}

.form-content-incorporation h1 {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 36px;
  line-height: 45px;
  margin: 1rem 0;
  margin-bottom: 2rem;
}



.logo-help-incorporation {
  width: 80%;
}

.uae-container-a {
  width: 22%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: #060056; */
}

.uae-img-cont {
  width: 90%;
  height: 230px;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.uae-img-cont img {
  max-width: 50%;
}

.uae-img-cont1 {
  width: 90%;
  height: 230px;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.uae-img-cont1 img {
  max-width: 40%;
}

.uae-img-cont2 {
  width: 90%;
  height: 230px;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.uae-img-cont2 img {
  max-width: 35%;
}

.uae__section-logo-container {
  max-width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3%;
}

.uae__section-logo-container-sub {
  display: flex;
  max-width: 80%;
  justify-content: space-around;
}

.uae__section-logo-container-two {
  flex: 1;
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 2rem;
  align-items: baseline;
  grid-template-columns: repeat(2, 1fr);
}

.uae__section-help {
  display: flex;
  max-width: 100%;
  /* background-color: rgb(174, 90, 90); */
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
}

.uae-container-a p {
  color: black;
  font-family: var(--font-family2);
  font-weight: 200;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  margin-top: 15px;
  max-width: 90%;
}

.uae__section-helpp {
  background: #f9f9f9;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: 20px;
  max-width: 80%;
  display: flex;
  padding: 2rem 5rem;
  margin-top: 2rem;
  color: white;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.uae__section-introo-content {
  text-align: left;
  max-width: 850px;
}

.uae__section-introo-content h1 {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 36px;
  line-height: 45px;
  margin: 1rem 0;
  margin-bottom: 2rem;
  color: var(--color-head);
}

.uae__section-introo-content p {
  color: black;
  font-family: var(--font-family2);
  font-weight: 200;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 2rem;
}

.uae__section-introo-content button {
  background: #f9f9f9;
  border-radius: 40px;
  color: #060056;
  font-style: normal;
  font-size: 18px;
  line-height: 30px;
  padding: 0.5rem 1rem;
  border-style: solid;
  border-width: thin;
  font-weight: 500;
  font-family: var(--font-family2);
  cursor: pointer;
  min-width: 200px;
  margin-top: 2rem;
}

.jobSeekerDivUae {
  max-width: 100%;
  margin-top: 4%;
  /* height: 400px; */
  display: flex;
  justify-content: center;
}

.jobSeekerSubDivUae {
  max-width: 70%;
  border-radius: 30px;
  height: 350px;
  border-top-left-radius: 180px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.jobSeekerLeftSectionUae {
  max-width: 60%;
  padding-left: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.jobSeekerRightSection {
  max-width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.jobHeading {
  font-family: var(--font-family);
  font-size: 30px;
  color: #000;
  margin-top: 10px;
  letter-spacing: 2px;
}

.paraSeekerDiv {
  margin-top: 30px;
}

.paraSeekerUae {
  font-family: var(--font-family2);
  font-size: 18px;
  line-height: 29px;
  color: #000000;
}

.seekerImg {
  max-width: 80%;
}

.generalProcessDiv {
  max-width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.headindDivGenerl {
  max-width: 80%;
}

.generalProcessSubDiv {
  max-width: 80%;
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  border-bottom: 1px solid #999999;
  padding-bottom: 10px;
}

.leftSectionGeneral {
  max-width: 70%;
}

.rightSectionGeneral {
  max-width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rightSectionImg {
  max-width: 30%;
  height: 70%;
}

.subHeadingGeneral {
  font-family: var(--font-family);
  color: #12218e;
  font-size: 22px;
}

.subParaGeneral {
  font-family: var(--font-family2);
  margin-top: 10px;
  color: #000;
  font-size: 18px;
  max-width: 80%;
  text-align: justify;
  line-height: 23px;
}

.uae_section_helpp_main_div {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
}

.uae__section-helpp {
  background-image: url(../../assets/Group_491.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom right;
  border-radius: 28px;
  box-shadow: 0px 0px 8px 0px lightgray;
  border: 20px;
  flex: 1;
  display: flex;
  padding: 2rem 5rem;
  margin-top: 2rem;
  margin-bottom: 5rem;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.uae__section-introo-content {
  text-align: left;
  max-width: 850px;
}

.uae__section-introo-content h1 {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 36px;
  line-height: 45px;
  margin: 1rem 0;
  margin-bottom: 2rem;
  color: var(--color-head);
}

.uae__section-introo-content p {
  color: black;
  font-family: var(--font-family2);
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 2rem;
  text-align: justify;
}

.uae__section-introo-content button {
  background: #f9f9f9;
  border-radius: 40px;
  color: #060056;
  font-style: normal;
  font-size: 18px;
  line-height: 30px;
  padding: 0.5rem 1rem;
  border-style: solid;
  border-width: thin;
  font-weight: 500;
  font-family: var(--font-family2);
  cursor: pointer;
  min-width: 200px;
  margin-top: 2rem;
}

#uae__section-logo-container-sub-1 {
  display: none;
}

.companyFormationText {
  width: 70%;
  margin-top: 5%;
}

.companyFormationText h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 35px;
  font-family: var(--font-family);
  /* text-align: center; */
  width: 55%;
}

.companyFormationSection {
  margin-bottom: 5rem;
  margin-top: 2rem;
  background-color: #f9f9f9;
  width: 70%;
  border-radius: 10px;
  padding: 15px;
  padding-left: 25px;
  display: flex;
  flex-direction: row;
}

.companyFormationSection h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 35px;
  font-family: var(--font-family);
  color: #060056;
  /* text-align: center; */
  /* width: 55%; */
}

.companyFormationSection p {
  font-style: normal;
  /* font-weight: 00; */
  font-size: 18px;
  line-height: 30px;
  font-family: var(--font-family2);
  color: #5a5a5a;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.companyFormationSection li {
  font-style: normal;
  /* font-weight: 00; */
  font-size: 18px;
  /* line-height: 30px; */
  font-family: var(--font-family2);
  color: #5a5a5a;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 0.9rem;
}

.leftSectionFormation {
  width: 60%;
}

.rightSectionFormation {
  width: 40%;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
}

.rightSectionFormationImg {
  width: 120%;
 
  position: absolute;
  right: -100px;
}

.formFormationSection {
  width: 100%;

  background-image: url('../../assets/formbg.jpg');
  background-size: cover;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}

.formFormationSection h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 35px;
  font-family: var(--font-family);
  /* color: #060056; */
}

.formFormationSection h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 35px;
  font-family: var(--font-family);
  text-align: center;
  width: 70%;
  margin-top: 1rem;
  color: #060056;
}

.inputFieldFormation {
  padding: 10px 20px 10px 20px;
  border-radius: 15px;
  border: solid 1.9px #707070;
  background-color: #fff;
  width: 45%;
  font-size: 17px;
  font-family: var(--font-family2);
  color: #000000;
}

.inputFieldFormation1 {
  padding: 10px 20px 10px 20px;
  border-radius: 15px;
  border: solid 1.9px #707070;
  background-color: #fff;
  width: 45%;
  font-size: 17px;
  font-family: var(--font-family2);
  color: #000000;
  /* line-height: 100px; */
}

.inputFieldFormation2 {
  padding: 10px 20px 10px 20px;
  border-radius: 15px;
  border: solid 1.9px #707070;
  background-color: #fff;
  width: 100%;
  font-size: 17px;
  font-family: var(--font-family2);
  color: #000000;
  margin-bottom: 2rem;
}

.inputDiv {
  width: 70%;
  /* background-color: #000000; */
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.inputDiv1 {
  flex-direction: column;
  display: flex;
  width: 45%;
  height: 120px;
  justify-content: space-between;
  
}

/* flexDirection: 'column', display: 'flex', width: '45%', height: '120px', justifyContent: 'space-between' }} */
.buttonsDiv {
  width: 70%;
  /* background-color: #5a5a5a; */
  margin-top: 1%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 5%;
}

.submitButton {
  border-radius: 20px;
  font-family: var(--font-family2);
  font-size: 19px;
  background-color: #060056;
  margin-right: 5%;
  color: white;
  padding: 7px 40px 7px 40px;
}

.resetButton {
  border-radius: 20px;
  font-family: var(--font-family2);
  font-size: 19px;
  background-color: #ee0324;
  color: white;
  padding: 7px 40px 7px 40px;
}

@media screen and (max-width: 1270px) {
  .uae__section-logo-container {
    flex-direction: column;
  }

  .uae__section-logo-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .uae__section-logo-container-two {
    flex-direction: column;
  }

  .uae__section-introo-content p {
    max-width: 100%;
    text-align: justify;
  }
}

@media screen and (max-width: 1150px) {
  .uae__section-logo-container-sub {
    max-width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .jobSeekerSubDivUae {
    max-width: 80%;
  }

  .uae__section-logo-container {
    max-width: 85%;
  }
}

@media screen and (max-width: 950px) {
  .uae__section-logo-container {
    max-width: 90%;
  }

  .jobSeekerSubDivUae {
    max-width: 90%;
  }

  .seekerImg {
    max-width: 90%;
  }
}

@media screen and (max-width: 850px) {
  .uae-img-cont {
    max-width: 100%;
  }

  .uae-img-cont1 {
    max-width: 100%;
  }

  .uae-img-cont2 {
    max-width: 100%;
  }

  .jobSeekerSubDivUae {
    max-width: 90%;
  }

  .uae__section-helpp {
    max-width: 90%;
    padding: 2rem 3rem;
  }

  .uae__section-incorporation-content p {
    width: 100%;
  }

  .companyFormationText h1 {
    width: 90%;
  }

  .rightSectionFormation {
    display: none;
  }

  .leftSectionFormation {
    width: 100%;
  }

  .uae__incorporation-content h1 {
    font-size: 25px;
  }

  .inputDiv {
    width: 85%;
  }

  .buttonsDiv {
    width: 85%;
  }

  .formFormationSection h4 {
    width: 85%;
  }

}

@media screen and (max-width: 750px) {
  .uae__incorporation {
    max-width: 90%;
  }

  .jobSeekerLeftSectionUae {
    padding-left: 0;
    max-width: 60%;
  }

  .jobSeekerDivUae {
    margin-top: 0;
  }

  .jobSeekerRightSection {
    max-width: 35%;
    margin-left: 5%;
  }

  .seekerImg {
    max-width: 100%;
  }

  .jobHeading {
    font-size: 25px;
  }

  .paraSeekerUae {
    font-size: 16px;
    text-align: justify;
  }
}

@media screen and (max-width: 700px) {
  .subParaGeneral{
font-size: 16px;
  }
  .uae__incorporation-image img {
    max-width: 100%;
    height: 100%;
  }

  .uae__section-incorporation-content p {
    font-size: 16px;
  }

  .form-container-a-incorporation h1 {
    font-size: 30px;
  }

  .form-container-a-incorporation p {
    font-size: 16px;
    text-align: justify;
  }

  .uae__section-incorporation-content h1 {
    font-size: 30px;
  }

  .uae__section-introo-content p {
    font-size: 16px;
  }

  .uae__section-introo-content h1 {
    max-width: 100%;
    font-size: 30px;
  }

  .uae__section-logo-container {
    grid-template-columns: repeat(1, 1fr);
  }

  .uae__section-logo-container-two {
    grid-template-columns: repeat(1, 1fr);
  }

  .uae__incorporation-content p {
    font-size: 16px;
  }

  .uae__section-introo-content p {
    font-size: 16px;
  }

  .uae__section-introo-content h1 {
    font-size: 30px;
  }
}
.fcontact__section-form{
  margin: 2rem;
}
.companyFormationSection {
  font-size: 16px;
}
@media screen and (max-width: 600px) {
  .uae__incorporation {
    flex-direction: column;
    align-items: center;
    margin-bottom: 4rem;
  }

  .uae__incorporation-content {
    max-width: 80%;
  }

  .uae__incorporation-image {
    max-width: 80%;
    margin-top: 3%;
  }

  .uae-container-a {
    width: 27%;
  }

  .uae__incorporation-content h1 {
    text-align: center;
  }

  .jobSeekerSubDivUae {
    flex-direction: column;
    height: auto;
    align-items: center;
  }

  .jobSeekerLeftSectionUae {
    max-width: 90%;
  }

  .jobSeekerRightSection {
    max-width: 60%;
    margin-top: 5%;
  }

  .uae__section-helpp {
    padding: 1rem 2rem;
  }

  .uae__section-introo-content h1 {
    font-size: 25px;
    margin: 0;
  }

  .htag-text {
    font-size: 20px;
  }
}

@media screen and (max-width: 500px) {
  .uae__section-logo-container-sub {
    flex-direction: column;
    display: contents;
  }

  #uae-container-a-3 {
    display: none;
  }

  #uae-container-a-6 {
    display: none;
  }

  #uae__section-logo-container-sub-1 {
    display: contents;
    /* margin-top: 40px; */
  }

  .uae-container-a {
    width: 60%;
    /* background-color: #999999; */
    margin-top: 35px;
  }
}

@media screen and (max-width: 450px) {
  .uae__incorporation-image {
    max-width: 95%;
  }

  .uae__incorporation-content {
    max-width: 94%;
  }

  .uae-img-cont {
    height: 250px;
  }

  .uae-img-cont1 {
    height: 250px;
  }

  .uae-img-cont2 {
    height: 250px;
  }

  .uae-container-a p {
    font-size: 16px;
  }

  .form-content-incorporation h1 {
    font-size: 28px;
  }

  .companyFormationText h1 {
    font-size: 23px;
  }

  .companyFormationSection {
    width: 80%;
  }

  .inputDiv {
    flex-direction: column;
    margin-top: 0px;
  }

  .inputFieldFormation {
    width: 100%;
    margin-top: 15px;
  }

  .inputFieldFormation2 {
    width: 100%;
    margin-top: 15px;
  }

  .inputFieldFormation1 {
    width: 100%;
    margin-top: 15px;
  }

  .inputDiv1 {
    width: 100%;
    height: auto;
  }
  .companyFormationText h1 {
    width: 100%;
  }
}

@media screen and (min-width: 1450px) {
  .uae__section-incorporation {
    max-width: 100%;
  }

  .uae__section-help {
    width: 80%;
  }

  .uae__section-logo-container {
    max-width: 80%;
  }

  .jobSeekerDivUae {
    max-width: 80%;
  }

  .jobSeekerSubDivUae {
    width: 90%;
  }
}