.contact__possibilitytop {
  display: flex;
  flex-direction: row;
  margin-bottom: 5rem;
  border-radius: 20px;
}

.contact__left-text {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.contact__left-text h1 {
  font-family: var(--font-family);
  font-weight: 600;

  font-size: 36px;
  line-height: 45px;
  margin: 1rem 0;
  color: var(--color-head);
}
.swal-css {
  color: rgb(0, 0, 0);
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 28px;
  line-height: 30px;
  color: var(--color-head);
}
.swal2-popup {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.contact__left-text p {
  color: rgb(0, 0, 0);
  font-family: var(--font-family2);
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
}

.contact__right-text {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact__right-text p {
  color: rgb(0, 0, 0);
  font-family: var(--font-family2);
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
}
.contact__right-text button {
  background: #060056;
  border-radius: 10px;
  color: white;
  font-style: normal;
  margin-top: 1rem;
  font-size: 18px;
  line-height: 30px;
  padding: 0.5rem 3rem;
  border: none;
  outline: none;
  font-family: var(--font-family2);
  cursor: pointer;
  min-width: 150px;
}
.contact__possibility {
  display: flex;
  flex-direction: row;
  margin-bottom: 5rem;
}
.contact__possibility-image {
  flex: 1;
  display: flex;
  margin-right: 6rem;
}
.contact__possibility-image img {
  width: 100%;
  height: 100%;
}
.contact__possibility-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.contact__possibility-content h1 {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 36px;
  line-height: 45px;
  margin: 1rem 0;
}

.contact__possibility-content p {
  color: var(--color-text);
  font-family: var(--font-family2);
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 2rem;
}

/* Section intro */
.contact__section-intro {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.contact__section-intro-content h1 {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 36px;
  line-height: 45px;
  margin: 1rem 0;
  margin-bottom: 2rem;
}
.contact__section-intro-content p {
  color: var(--color-text);
  font-family: var(--font-family2);
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;

  margin-bottom: 2rem;
}

.future {
  color: #ef0325;
}

.form__input-contact {
  font-family: "Roboto", sans-serif;
  color: #333;
  font-size: 1rem;

  padding: 1.5rem 1rem;
  padding-right: 15rem;
  border-radius: 10px;
  background-color: rgb(242, 238, 238);
  border: none;
  display: block;
  margin-top: 2rem;
  transition: all 0.3s;
}
input {
  width: 100%;
}
.contact__section-form textarea {
  margin-bottom: 2rem;
}

@media screen and (max-width: 1050px) {
  .contact__possibility {
    flex-direction: column;
    margin-bottom: 0rem;
  }

  .contact__possibility-image {
    margin: 1rem 0;
  }

  .contact__possibility-content {
    margin-top: 2rem;
  }
}

@media screen and (max-width: 1000px) {
  .contact__possibility-image img {
    width: 100%;
    height: 100%;
  }

  .contact__right-text {
    justify-content: center;
  }
  .contact__possibilitytop {
    flex-direction: column;
  }

  .contact__left-text h1 {
    font-size: 30px;
  }
  .contact__sectiontwo h1 {
    font-size: 30px;
  }
  .form__input-contact {
    font-size: 16px;
  }
  .contact__section-intro-content p {
    font-size: 16px;
  }
  .contact__section-intro-content h1 {
    font-size: 30px;
  }
  .contact__possibility-content h1 {
    font-size: 30px;
  }
}
@media screen and (max-width: 700px) {
  .form__input-contact {
    width: 100%;
    padding-right: 0%;
  }
}
