/* First Container CSS */
.services__possibility {
  display: flex;
  flex-direction: row;
}

.services__possibility-content-heading h1 {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 34px;
  line-height: 30px;
  margin: 1rem 0;
}

.services__possibility-image {
  flex: 1;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  max-width: 35rem;
  padding: 4rem;
}

.services__possibility-image img {
  width: 100%;
  height: 100%;
}

.services__possibility-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.services__possibility-content img {
  position: relative;
  left: 0;
  top: 100;
}

.services__possibility-content h1 {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 34px;
  line-height: 45px;
  margin: 1rem 0;
  color: var(--color-head);
}

.services__possibility-content p {
  color: var(--color-text);
  font-family: var(--font-family2);
  font-weight: 200;
  font-size: 18px;
  line-height: 30px;

  margin-bottom: 2rem;
}

.about__possibility-image img {
  width: 100%;
  height: 100%;
}

/* Flaggy */
.service-main {
  padding: 6rem 0rem;
}

.service-main img {
  height: 200px;
}

.service-main-right img {
  height: 200px;
}

.imgcon {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

/* 2 grid compo */

.ser-sub-cont {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ser__section-logo-container {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  align-items: baseline;
}

.ser__section-help {
  flex: 1;
  display: flex;
  width: 100%;
  color: white;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
}

.ser-container-a {
  max-width: 30rem;
  display: flex;
  flex-direction: column;

  justify-content: center;
  padding: 1.5rem;
}

.ser-container-a p {
  color: black;
  font-family: var(--font-family2);
  font-weight: 200;
  font-size: 18px;
  line-height: 30px;
  text-align: left;
  margin-top: 1rem;
}

.ser-container-a h1 {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 20px;
  line-height: 45px;
  margin: 1rem 6px;
  color: #312b73;
}

.services__possibility button {
  background: #060056;
  border-radius: 10px;
  color: white;
  font-style: normal;
  margin-top: 1rem;
  font-size: 18px;
  line-height: 30px;
  padding: 0.5rem 3rem;
  border: none;
  outline: none;
  font-family: var(--font-family2);
  cursor: pointer;
  min-width: 150px;
}

@media screen and (max-width: 1450px) {}

@media screen and (max-width: 1250px) {
  .services__expect-main {
    overflow-x: hidden;
  }

  .services__possibility {
    flex-direction: column;
    margin-bottom: 0rem;
  }

  .services__possibility-image {
    margin: 1rem 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .services__possibility-content {
    margin-top: 2rem;
  }

  .ser__section-logo-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 700px) {
  .ser-img-cont{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .ser-img-cont img{
    width: 150px;
  }
  .services__possibility-image img {
    width: 100%;
    height: 100%;
  }

  .service-main img {
    height: 130px;
  }

  .service-main-right img {
    height: 130px;
  }

  .services__possibility-image {
    padding: 0rem;
  }

  .services__possibility-content p {
    font-size: 16px;
  }

  .services__possibility-content-heading h1 {
    font-size: 30px;
  }

  .services__possibility-content h1 {
    font-size: 30px;
  }
}