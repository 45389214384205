.immi__possiblity-main {
  width: 100%;
  display: flex;
  justify-content: center;
}

.immi__possibility {
  display: flex;
  flex-direction: row;
  margin-bottom: 5rem;
  width: 80%;
  margin-top: 2rem;
  /* background-color: antiquewhite; */
}

.immi__possibility-image {
  /* flex: 1; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* max-width: 35rem; */
  /* margin-right: 2rem; */
  width: 40%;
}

.immi__possibility-image img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

.immi__possibility-content {
  /* flex: 1; */
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-right: 5rem;
}

.immi__possibility-content h1 {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 35px;
  /* line-height: 55px; */
  margin: 1rem 0;
}

.immi__possibility-content p {
  color: var(--color-text);
  font-family: var(--font-family2);
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;

  margin-bottom: 2rem;
}

@media screen and (max-width: 1200px) {
  .immi__possibility {
    width: 90%;
  }

  .immi__possibility-content {
    margin-right: 0;
  }
}

@media screen and (max-width: 1070px) {
  .immi__possibility-content {
    margin-top: 2rem;
    width: 50%;
  }

  .immi__possibility-image {
    margin: 1rem 0;
    width: 48%;
    margin-left: 2%;
  }
}

@media screen and (max-width: 950px) {
  .immi__possibility-content h1 {
    font-size: 25px;
  }

  .immi__possibility-content p {
    font-size: 15px;
  }
}

@media screen and (max-width: 850px) {
  .immi__possibility {
    flex-direction: column;
    margin-bottom: 0;
  }

  .immi__possibility-content {
    width: 100%;
  }

  .immi__possibility-image {
    width: 100%;
    height: 345px;
    margin: 0;
    align-items: flex-start;
  }
  .immi__possibility-image img {
    height: 90%;
  }
}

@media screen and (max-width: 700px) {
  .immi__possibility-image img {
    width: 100%;
    /* height: 100%; */
  }

  .immi__possibility-content p {
    font-size: 18px;
  }

  .immi__possibility-content h1 {
    font-size: 34px;
    line-height: 42px;
  }
}

@media screen and (max-width: 500px) {
  .immi__possibility-image img {
    height: 70%;
  }
}

@media screen and (max-width: 414px) {
  .immi__possibility-image img {
    height: 80%;
  }
  .immi__possibility-image {
    height: 200px;
  }
}
