.mainContainerBlog {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.banner-container {
  width: 80%;
}
.subContainerBlog {
  width: 80%;
  display: flex;
  flex-direction: column;
  margin: 5% 0;
}

.blogSectionDiv {
  width: 100%;
  display: flex;
}

.blogSubSection {
  width: 30%;
  /* background-color: aqua; */
  height: 530px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* margin-left: 20px; */
  margin-top: 5%;
}

.blogSubSection11 {
  width: 100%;
  height: 510px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 20px;
  /* margin-top: 10%; */
}

.blogSubSection5 {
  width: 100%;
  height: 510px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 20px;
}


.headingBlog {
  font-size: 18px;
  font-family: var(--font-family);
  color: #060056;
  margin-top: 20px;
  height: 100px;
  /* background-color: antiquewhite; */
}

.imageBlog {
  width: 100%;
  height: 180px;
  border-radius: 10px;
}

.imageBlog55 {
  width: 100%;
  height: 310px;
  border-radius: 10px;
}

.subHeadingBlog {
  font-size: 16px;
  font-family: var(--font-family);
  color: #000000;
}

.paraBlog {
  font-size: 16px;
  font-family: var(--font-family2);
  color: #000000;
  height: 100px;
  /* background-color: aquamarine; */
}

.blogButton {
  width: 120px;
  border: 1.7px solid #060056;
  border-radius: 50px;
  background-color: white;
  font-family: var(--font-family2);
  color: #060056;
  padding: 7px 0px;
  cursor: pointer;
  font-weight: 600;
}

.blogButton:hover {
  background-color: #060056;
  color: white;
}

.silce3Div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* background-color: #060056; */
}

.imageBlog4 {
  width: 100%;
  height: 300px;
  border-radius: 10px;
}

.silceDivMain {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 5%;
}

.silce4Div {
  width: 60%;
}

.silce5Div {
  width: 39%;
  margin-left: 2%; 
  /* margin-top: 6%; */
}

.silceDivvvAll {
  width: 100%;
  margin-top: 5%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.view-btn-style {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.view-button {
  width: 150px;
  border: 1.7px solid #060056;
  border-radius: 50px;
  background-color: white;
  font-family: var(--font-family2);
  color: #060056;
  padding: 10px 0px;
  cursor: pointer;
  font-weight: 600;
}

.view-button:hover {
  background-color: #060056;
  color: white;
}

@media screen and (min-width: 1550px) {
  .subContainerBlog {
    width: 60% !important;
  }
  .subContainerBlogAll {
    width: 60% !important;
  }
  .silce3Div {
    width: 100%;
    /* background-color: antiquewhite; */
  }

  .blogSubSection {
    width: 31%;
    /* background-color: #060056; */
  }
  .imageBlog {
    height: 210px;
  }
  .silce4Div {
    width: 62%;
  }
  .bottomBlogList {
    width: 70% !important;
    margin-bottom: 3%;
   }
}
