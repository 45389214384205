.gpt3__cta {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  padding: 3rem;
  text-align: left;
  align-items: center;
}

.gpt3__cta-content h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 25px;
  font-family: var(--font-family);
}
.gpt3__cta-content p {
  color: var(--color-text);
  font-family: var(--font-family2);
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
}
.gpt3__cta-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5rem;
}
.gpt3__cta-btn button {
  background: #060056;
  border-radius: 40px;
  color: white;
  font-style: normal;

  font-size: 16px;
  line-height: 30px;
  padding: 0.5rem 1rem;
  border: none;
  outline: none;
  font-family: var(--font-family2);
  cursor: pointer;
  min-width: 150px;
}

@media screen and (max-width: 650px) {
  .gpt3__cta {
    flex-direction: column;
  }

  .gpt3__cta-btn {
    margin: 2rem 0 0;
  }
}

@media screen and (max-width: 550px) {
  .gpt3__cta {
    flex-direction: column;
    /* margin: 4rem 2rem; */
  }

  .gpt3__cta-content h3 {
    font-size: 30px;
    line-height: 32px;
  }

  .gpt3__cta-btn button {
    font-size: 16px;
    line-height: 28px;
  }
}
