
.banner-container321 {
  width: 100%;
  display: flex;
  justify-content: center;
}
.banner-containersubb {
  width: 80%;
}
.subContainerBlogAll {
  width: 80%;
  /* background-color: bisque; */
  /* height: 1000px; */
  display: flex;
  flex-direction: column;
  margin: 5% 0;
}
.silce3DivAllBlogs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.blogSubSectionAll {
  width: 31%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 20px;
  margin-bottom: 10px;
  /* background-color: red; */
  margin-top: 5%;
}

.headText {
  font-family: "Orbitron", sans-serif;
  color: #01244e;
  letter-spacing: 3px;
  font-size: 19px;
}

.blogButton {
    width: 120px;
    border: 1.7px solid #060056;
    border-radius: 50px;
    background-color: white;
    font-family: var(--font-family2);
    color: #060056;
    padding: 7px 0px;
    cursor: pointer;
    font-weight: 600;
  }
  
  .blogButton:hover {
    background-color: #060056;
    color: white;
  }


@media screen and (max-width: 1280px) {
  .blogSubSectionAll {
    width: 30%;
  }
}

@media screen and (max-width: 800px) {
  .blogSubSectionAll {
    width: 45%;
  }
  .subContainerBlog {
    width: 90%;
  }
}

@media screen and (max-width: 550px) {
  .blogSubSectionAll {
    width: 100%;
  }
  .silce3Div {
    flex-wrap: wrap;
    width: 100%;
  }
  .blogSubSection {
    width: 100%;
    height: 450px;
  }
  .silceDivMain {
    flex-direction: column;
  }
  .silce4Div {
    width: 100%;
  }
  .imageBlog4 {
    width: 95%;
  }
  .silce5Div {
    width: 100%;
    margin-left: 0%;
  }
}