.about__possibility {
  display: flex;
  flex-direction: row;
  margin-bottom: 5rem;
}
.about__possibilitytop {
  display: flex;
  flex-direction: row;
  margin-bottom: 5rem;
  background-color: #f9f9f9f9;
  border-radius: 20px;
}
.about__possibilitytwo {
  display: flex;
  flex-direction: row;
  margin-bottom: 5rem;
}
.about__possibility-image {
  flex: 1 1;
  display: flex;
  margin-right: 1rem;
  width: 100%;
  justify-content: center;
  align-items: center;
  max-width: 35rem;
  padding: 2.5rem;
}
.about__possibility-image img {
  width: 100%;
  height: 100%;
}
.about__possibility-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-right: 2rem;
  margin-left: 2rem;
}
.about__right-text {
  background-color: #312b73;
  border-top-left-radius: 80px 80px;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  padding: 3rem;
}
.about__right-text p {
  color: white;
  font-family: var(--font-family2);
  font-weight: 200;
  font-size: 18px;
  line-height: 30px;
  text-align: justify;
}

.about__possibility-content-heading {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  margin-bottom: 3rem;
  background: #f9f9f9;
  border-radius: 20px;
}

.about__left-text {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.about__left-text h1 {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 36px;
  line-height: 45px;
  margin: 1rem 0;
}
.about__left-text p {
  color: var(--color-text);
  font-family: var(--font-family2);
  font-weight: 200;
  font-size: 18px;
  line-height: 30px;

  margin-bottom: 2rem;
}
.about__rightContainer p {
  color: var(--color-text);
  font-family: var(--font-family2);
  font-weight: 200;
  font-size: 18px;
  line-height: 30px;
}

.about__possibility-content h1 {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 36px;
  line-height: 45px;
  margin: 1rem 0;
  color: var(--color-head);
}

.about__possibility-content p {
  color: var(--color-text);
  font-family: var(--font-family2);
  font-weight: 200;
  font-size: 18px;
  line-height: 27px;

  margin-bottom: 2rem;
}

@media screen and (max-width: 1400px) {
  .about__expect-main{
    overflow-x: hidden;
  }
  .about__possibility {
    flex-direction: column;
    margin-bottom: 0rem;
    align-items: center;
  }
  .about__possibilitytop {
    flex-direction: column;
  }
  .about__possibilitytwo {
    flex-direction: column-reverse;
    margin-bottom: 0rem;
    align-items: center;
  }
  .about__possibility-image {
    margin: 1rem 0;
  }

  .about__possibility-content-heading {
    display: flex;
    flex-direction: column;
  }
  .about__left-text {
    margin: 2rem;
  }

  .about__possibility-content {
    margin-top: 2rem;
    margin-right: 0px;
    margin-left: 0px;
  }
}
@media screen and (max-width: 700px) {
  .about__possibility-image img {
    width: 100%;
    height: 100%;
  }
  .about__right-text p {
    font-size: 16px;
  }
  .about__left-text p {
    font-size: 16px;
  }
  .about__rightContainer p {
    font-size: 16px;
  }
  .about__possibility-content p {
    font-size: 16px;
  }
  .about__left-text h1 {
    font-size: 30px;
  }
  .about__possibility-content h1 {
    font-size: 30px;
  }
}
