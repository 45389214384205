.info__features-container__feature-title {
  flex: 1;
  /* max-width: 250px; */
  margin-right: 3rem;
}

.info__features-container__feature-title h1 {
  font-family: var(--font-family);
  font-weight: 600;
  line-height: 35px;
  font-size: 25px;
  /* identical to box height, or 312% */
  letter-spacing: -0.04em;
  color: #060056;
}

.info__features-container_feature-text {
  flex: 2;

  display: flex;
  margin-top: 1.5rem;
}
.info__features-container_feature-text p {
  font-family: var(--font-family2);
  font-weight: 200;
  line-height: 30px;
  color: black;
}
.info__features-container_feature-text {
  font-family: var(--font-family2);
  font-weight: 200;
  line-height: 30px;
  color: black;
}
.info__features-container__feature {
  width: 100%;
  display: flex;

  align-items: center;
  align-items: flex-start;
  flex-direction: column;
  margin: 1rem;
}

@media screen and (max-width: 700px) {
  .info__features-container__feature-title h1 {
    font-size: 20px;
    line-height: 30px;
  }
  .info__features-container_feature-text p {
    font-size: 16px;
    line-height: 20px;
  }
  .info__features-container__feature {
    margin: 1rem 0;
  }
}
