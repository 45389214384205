.position__cta {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 3rem;
  text-align: center;
  background-color: var(--color-grey);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  margin-bottom: 5rem;
}

.position__cta-content h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  font-family: var(--font-family);
}
.position__cta-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  flex-direction: column;
}
.position__container {
  display: flex;
  align-items: center;
}
.position__cta-btn input {
  /* background: #060056;
  border-radius: 40px;
  color: white;
  font-style: normal;

  font-size: 18px;
  line-height: 30px;
  padding: 0.5rem 3rem;
  border: none;
  outline: none;
  font-family: var(--font-family2);
  cursor: pointer;
  min-width: 150px; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.position__cta-btn button {
  background: #060056;
  border-radius: 40px;
  color: white;
  font-style: normal;
  margin-top: 2rem;
  font-size: 18px;
  line-height: 30px;
  padding: 0.5rem 3rem;
  border: none;
  outline: none;
  font-family: var(--font-family2);
  cursor: pointer;
  min-width: 150px;
}

.position__right-text p{
  margin-top: 1rem;
 color: rgb(0, 0, 0);
  font-family: var(--font-family2);
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
}
@media screen and (max-width: 650px) {
  .position__cta {
    flex-direction: column;
  }

  .position__cta-btn {
    margin: 2rem 0 0;
  }
}

@media screen and (max-width: 550px) {
  .position__cta {
    flex-direction: column;
    /* margin: 4rem 2rem; */
  }

  .position__cta-content h3 {
    font-size: 18px;
    line-height: 32px;
  }

  .position__cta-btn input {
    font-size: 14px;
    line-height: 28px;
  }
}
