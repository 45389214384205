.germany__possiblity-main {
    width: 100%;
    /* background-color: #000000; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5%;
  }
  
  .germany__possibility {
    display: flex;
    flex-direction: row;
    margin-bottom: 8rem;
    width: 80%;
  }
  
  .germany__possibility-image {
    width: 45%;
    display: flex;
    /* margin-right: 6rem; */
  }
  
  .germany__possibility-image img {
    width: 100%;
    height: 100%;
  }
  
  .germany__possibility-content {
    width: 55%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .germany__possibility-content h1 {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 36px;
    line-height: 45px;
    margin: 1rem 0;
  }
  
  .germany__possibility-content p {
    color: var(--color-text);
    font-family: var(--font-family2);
    font-weight: 200;
    font-size: 18px;
    line-height: 30px;
  
    margin-bottom: 2rem;
  }
  
  .germany__section-ability {
    display: flex;
    flex-direction: column;
    width: 80%;
    align-items: center;
  }
  
  .germany__section-ability-content {
    width: 80%;
  }
  
  .generalHeading {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 36px;
    line-height: 45px;
    margin: 1rem 0;
    margin-bottom: 2rem;
    color: black;
    text-align: left;
  }
  
  .germany__section-ability-content p {
    color: var(--color-text);
    font-family: var(--font-family2);
    font-weight: 200;
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 2rem;
  }
  
  .germany-container-a {
    width: 16%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
  }
  
  .germany-img-cont-germany {
    width: 90%;
    height: 105px;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #f8f8f8;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .germany-img-cont-germany img {
    width: 60%;
  }
  
  .germany-img-cont1germany {
    width: 90%;
    height: 105px;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #f8f8f8;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .germany-img-cont3germany {
    width: 90%;
    height: 105px;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #f8f8f8;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .germany-img-cont3germany img  {
    width: 50%;
  }
  
  .germany-img-cont1germany img {
    width: 40%;
  }
  
  .germany-img-cont2germany {
    width: 90%;
    height: 105px;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #f8f8f8;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .germany-img-cont2germany img {
    width: 35%;
  }
  
  .germany__section-logo-container {
    flex: 1;
    width: 80%;
    /* background-color: red; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .germany__section-logo-container-two {
    flex: 1;
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 2rem;
    align-items: baseline;
    grid-template-columns: repeat(2, 1fr);
  }
  
  .germany__section-help {
    display: flex;
    width: 80%;
    color: white;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3rem;
  }
  
  .germany-container-a p {
    color: black;
    font-family: var(--font-family2);
    font-weight: 200;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin-top: 15px;
    width: 100%;
  }
  
  .germany__section-helpp {
    background: #f9f9f9;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border: 20px;
    flex: 1;
    display: flex;
    padding: 2rem 5rem;
    margin-top: 2rem;
    color: white;
    display: flex;
    flex-direction: column;
    text-align: center;
    /* width: 90%; */
  }
  
  
  .jobSeekerDivgermany {
    width: 80%;
    /* background-color: #d5ffae; */
    height: 400px;
    display: flex;
    justify-content: center;
    margin-top: 5%;
  }
  
  .jobSeekerSubDivGermany {
    width: 80%;
    background-color: #f8f8f8;
    border-radius: 30px;
    height: 350px;
    border-top-left-radius: 180px;
    background-image: url("../../assets/bgPortugalJob.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: row;
    /* background-position: 'bottom'; */
  }
  
  .jobSeekerLeftSectiongermany {
    width: 60%;
    /* background-color: antiquewhite; */
    /* height: 100%; */
    padding-left: 10%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
  }
  
  .jobSeekerRightSectiongermany {
    width: 40%;
    /* background-color: #e02a2a; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .jobHeadinggermany {
    font-family: var(--font-family);
    font-size: 30px;
    color: #000;
    font-weight: 600;
    margin-top: 10px;
    letter-spacing: 2px;
  }
  
  .paraSeekerDiv {
    margin-top: 30px;
  }
  
  .paraSeeker {
    font-family: var(--font-family2);
    font-size: 16px;
    line-height: 25px;
    font-weight: 200;
    color: #000000;
  }
  
  .seekerImggermany {
    width: 80%;
  }
  
  .generalProcessDivgermany {
    width: 80%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 5%;
  }
  
  .headindDivGenerl {
    width: 80%;
  }
  
  .generalProcessSubDivgermany {
    width: 80%;
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    border-bottom: 1px solid #999999;
    padding-bottom: 10px;
    /* justify-content: center; */
    align-items: center;
  }
  
  .leftSectionGeneralgermany {
    width: 85%;
    /* background-color: aqua; */
    /* height: 200px; */
  }
  
  .rightSectionGeneralgermany {
    width: 15%;
    /* background-color: rgb(217, 255, 0); */
    /* height: 200px; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .rightSectionImggermany {
    width: 40%;
    /* height: 70%; */
  }

  .rightSectionImggermany1 {
    width: 60%;
  }
  
  .subHeadingGeneralGermany {
    font-family: var(--font-family);
    color: #12218e;
    font-size: 18px;
    font-weight: 600;
  }
  
  .subParaGeneralgermany {
    font-family: var(--font-family2);
    margin-top: 10px;
    color: #000;
    font-size: 16px;
    width: 80%;
    text-align: justify;
    line-height: 23px;
  }
  

  

  

  .germany_section_helpp_main_div {
    max-width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
  }
  
  .germany__section-helpp {
    background-image: url(../../assets/Group_491.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom right;
    border-radius: 28px;
    box-shadow: 0px 0px 8px 0px lightgray;
    border: 20px;
    flex: 1;
    display: flex;
    padding: 2rem 5rem;
    margin-top: 2rem;
    margin-bottom: 5rem;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  
  .germany__section-introo-content {
    text-align: left;
    max-width: 850px;
  }
  
  .germany__section-introo-content h1 {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 36px;
    /* line-height: 45px; */
    /* margin: 1rem 0; */
    margin-bottom: 1rem;
    color: var(--color-head);
    text-align: center;
  }
  
  .germany__section-introo-content li {
    color: black;
    font-family: var(--font-family2);
    font-weight: 100;
    font-size: 18px;
    line-height: 30px;
    /* margin-bottom: 2rem; */
    text-align: justify;
  }
  
  .germany__section-introo-content button {
    background: #f9f9f9;
    border-radius: 40px;
    color: #060056;
    font-style: normal;
    font-size: 16px;
    line-height: 30px;
    padding: 0.3rem 1rem;
    border-style: solid;
    border-width: thin;
    font-weight: 500;
    font-family: var(--font-family2);
    cursor: pointer;
    min-width: 200px;
    margin-top: 1rem;
  }



.applySection {
  width: 100%;
  padding: 40px;
  background-image: url('../../assets/group29.png');
  background-size: cover;
  display: flex;
  justify-content: center;
}

.subDivApplySection {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subDivApplySection h1 {
  text-align: center;
  font-family: var(--font-family);
  font-size: 25px;
  line-height: 40px;
}

.applyButton {
  background-color: #060056;
  color: white;
  font-family: var(--font-family2);
  font-size: 17px;
  padding: 11px 40px;
  border-radius: 5px;
  margin-top: 10px;
}

  @media screen and (max-width: 1050px) {
    .germany__section-logo-container {
      width: 80%;
    }
  
    .jobSeekerSubDivGermany {
      width: 90%;
    }
  }
  
  @media screen and (max-width: 900px) {
    .generalProcessSubDivgermany {
      width: 95%;
    }
    .rightSectionImggermany {
      width: 40%;
    }
    .germany__section-ability-content {
      width: 90%;
    }
  
    .germany__section-logo-container {
      width: 80%;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
    }
  
    .germany-container-a {
      width: 32%;
    }
  
    .jobHeadinggermany {
      font-size: 25px;
      margin-top: 10px;
    }
  
    .jobSeekerSubDivGermany {
      height: 300px;
      width: 94%;
    }
  
    .generalProcessDivgermany {
      margin-top: 3%;
    }
  
    .germany-img-cont-germany {
      width: 65%;
      border-radius: 15px;
    }
  
    .germany-img-cont1germany {
      width: 65%;
      border-radius: 15px;
    }
  
    .germany-img-cont2germany {
      width: 65%;
      border-radius: 15px;
    }
  
    .germany__possibility {
      width: 90%;
    }
  }
  
  @media screen and (max-width: 700px) {
    .germany-img-cont-germany {
      width: 70%;
    }
  
    .germany-img-cont1germany {
      width: 70%;
    }
  
    .germany-img-cont2germany {
      width: 70%;
    }
  
    .germany__section-logo-container {
      width: 90%;
    }
  
    .jobSeekerSubDivGermany {
      flex-direction: column;
      border-radius: 20px;
      height: 400px;
      padding-top: 20px;
    }
    .jobSeekerLeftSectiongermany {
      width: 90%;
    }
    .jobSeekerRightSectiongermany {
      width: 90%;
      margin-top: 20px;
      padding-top: 20px;
    }
    .seekerImggermany {
      width: 50%;
    }
    .generalProcessDivgermany {
      margin-top: 5%;
    }
    .jobSeekerDivgermany {
      height: auto;
    }
    .generalProcessSubDivgermany {
      width: 90%;
    }
    .leftSectionGeneralgermany {
      width: 80%;
    }
    .rightSectionGeneralgermany {
      width: 20%;
    }
    .rightSectionImggermany {
      width: 50%;
    }
  }
  
  @media screen and (max-width: 600px) {
    .generalProcessSubDivgermany {
      width: 80%;
      flex-direction: column;
    }
  
    .leftSectionGeneralgermany {
      width: 100%;
    }
  
    .subParaGeneralgermany {
      width: 90%;
    }
  
    .rightSectionImggermany {
      width: 30%;
      height: 90px;
    }
  
    .rightSectionGeneralgermany {
      width: 100%;
      margin-top: 40px;
    }
    .germany__possibility-content h1 {
      text-align: center;
    }
  
    .germany__possibility {
      flex-direction: column;
      align-items: center;
      margin-bottom: 4rem;
    }
  
    .germany__possibility-content {
      width: 80%;
    }
  
    .germany__possibility-image {
      width: 80%;
      margin-top: 3%;
    }
  
    .germany-container-a {
      width: 46%;
      margin-top: 20px;
    }
  
    .germany__section-logo-container {
      width: 70%;
      /* background-color: aqua; */
    }
  
    .germany__section-ability-content {
      width: 75%;
    }
  
    .germany__section-ability-content p {
      font-size: 16px;
      text-align: left;
    }
  }
  
  @media screen and (max-width: 414px) {
    .germany__section-logo-container {
      width: 90%;
      /* background-color: aqua; */
    }
    
    .rightSectionImggermany1 {
      width: 35%;
    }
  .rightSectionImggermany {
    width: 25%;
    height: 55px;
  }
  .rightSectionGeneralgermany {
    margin-top: 20px;
  }
  .germany__section-helpp {
    padding: 10px 30px;
  }
  .germany__section-introo-content li {
    text-align: left;
  }
  .subDivApplySection {
    width: 100%;
  }

  .subDivApplySection h1 {
    text-align: center;
    font-family: var(--font-family);
    font-size: 20px;
    line-height: 30px;
  }
   
  .subHeadingGeneralGermany {
    text-align: center;
    font-size: 16px;
    /* line-height: 25px; */
  }
    .germany__section-ability-content {
      width: 85%;
    }
  
    .germany-container-a {
      width: 50%;
    }
  
    .germany-img-cont-germany {
      width: 80%;
    }
    .germany-img-cont1germany {
      width: 80%;
    }
  
    .germany-img-cont2germany {
      width: 80%;
    }
  }
  