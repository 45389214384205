.fanuun__possibility {
  display: flex;
  flex-direction: row;
  margin-bottom: 5rem;
}

.fanuun__possibility-content {
  flex: 1;
  display: flex;
}

.fanuun__possibility-content h1 {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 58px;
  line-height: 55px;
  margin: 1rem 0;
}

.fanuun__possibility-content p {
  color: var(--color-text);
  font-family: var(--font-family2);
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;

  margin-bottom: 2rem;
}
.fanuun__right-cont {
  background: rgb(60, 54, 141);
  /* background: linear-gradient(
    299deg,
    rgba(209, 208, 224, 1) 0%,
    rgba(6, 0, 86, 1) 100%
  ); */
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 80px 80px;
  border-bottom-right-radius: 15px;
  flex: 1;
  display: flex;
  /* justify-content: flex-end;
  align-items: flex-end; */
  padding: 2rem 3rem;
  margin-top: 2rem;

  max-width: 30rem;
}
.fanuun__right-cont p {
  color: white;
  font-family: var(--font-family2);
  font-weight: 200;
  font-size: 18px;
  line-height: 30px;
}
/* testing */
.mainContainer-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media screen and (max-width: 1050px) {
  .fanuun__possibility {
    flex-direction: column;
    margin-bottom: 0rem;
  }

  .fanuun__possibility-content {
    margin-top: 2rem;
  }
}
@media screen and (max-width: 700px) {
  .fanuun__right-cont{
    width: 100%;
  }
  .fanuun__right-cont p {
    font-size: 16px;
  }
  .fanuun__possibility-content p {
    font-size: 16px;
  }
  .fanuun__possibility-content h1 {
    font-size: 30px;
    line-height: 42px;
  }
}
