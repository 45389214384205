
.section__padding1 {
  max-width: 1400px;
  padding: 2rem 8rem;
}
.us__possibility {
  display: flex;
  flex-direction: row;
  margin-bottom: 5rem;
  width: 100%;
  /* background-color: #060056; */
}
.us__possibility-image {
  width: 45%;
  display: flex;
  /* justify-content: flex-start;
  align-items: flex-start; */
  /* max-width: 35rem; */
  margin-right: 6rem;
}
.us__possibility-image img {
  width: 100%;
  height: 100%;
}
.us__possibility-content {
  width: 40%;
  /* background-color: #3e397c; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.us__possibility-content h1 {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 36px;
  line-height: 45px;
  margin: 1rem 0;
  text-align: center;
}

.us__possibility-content p {
  color: var(--color-text);
  font-family: var(--font-family2);
  font-weight: 200;
  font-size: 18px;
  line-height: 30px;

  margin-bottom: 2rem;
}
.us__section-intro-content {
  width: 90%;

}
.us__section-intro_main {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Section intro */
.us__section-intro {
  width: 75%;
  /* margin-top: 10rem; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: center;
  /* align-items: center; */
}
.banner_content_div {
  width: 85%;
  float: left;
  margin-left: 3rem;
}
.us__section-intro-content h1 {
  width: 100%;
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 36px;
  line-height: 45px;
  margin: 1rem 0;
  margin-bottom: 1rem;
}
.us__section-intro-content p {
  /* width: 100%; */
  color: var(--color-text);
  font-family: var(--font-family2);
  font-weight: 200;
  font-size: 18px;
  line-height: 35px;
  margin-bottom: 2rem;
  text-align: justify;
}

/* Section Ability */

.us__section-ability_main {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10rem;
  margin-bottom: 10rem;
}
.us__section-ability {
  width: 75% !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* margin-top: 10rem; */
  /* border: 2px solid #262453; */
  padding: 1rem 2rem;
  /* margin: 10rem 8rem 10rem 8rem; */
  border-radius: 20px;
  /* margin-bottom: 8rem; */
  box-shadow: 0px 0px 15px 0 lightgray;
}

.us__section-ability-content h1 {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 36px;
  line-height: 45px;
  margin: 1rem 0;
  margin-bottom: 2rem;
}
.us__section-ability-content p {
  color: var(--color-text);
  font-family: var(--font-family2);
  font-weight: 200;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 1rem;
}
.card_footer_div {
  width: 100%;
  /* margin-left: 2rem; */
}
/* New work */

.usSectionMainHelp {
  width: 100%;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  /* background-color: #060056; */
  background-image: url('../../assets/helpBanner.png');
  background-size: cover;
  margin-top: 7rem;
}

.us__section-help {
  width: 75%;
  background-image: url(../../assets/Group_488.png);
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 20px 150px 20px 20px;
  /* flex: 1; */
  display: flex;
  padding: 2rem 5rem 4rem 3rem;
  /* margin-top: 7rem; */
  color: white;
  display: flex;
  flex-direction: column;
}
.container-a {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.container-a img {
  width: 90.5px;
  height: 96.8px;
}
.container-a p {
  color: var(--color-text);
  font-family: var(--font-family2);
  font-weight: 200;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  margin-top: 1rem;
}
.container-b {
  width: 100%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 0px 10px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  background-color: #f9f9f9;
  border-radius: 20px;
  padding: 3rem 10px;
}
.container-b p {
  color: var(--color-text);
  font-family: var(--font-family2);
  font-weight: 200;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  margin-top: 1rem;
}

.us__section-logo-container {
  flex: 1;
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 2rem;
}

.us__section-advance-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 25px;
  margin-top: 2rem;
  /* flex: 1; */
  /* display: grid; */
  /* grid-template-columns: repeat(6, 220px); */
  /* grid-gap: 15px; */
  /* margin-left: 14rem; */
}

/* Last Section */
.us__possibilitytop {
  width: 75% !important;
  height: 300px;
  box-shadow: 0px 0px 10px 0px lightgray;
  /* margin: 9rem 8rem 3rem 8rem; */
  display: flex;
  flex-direction: row;
  /* margin-bottom: 5rem; */
  background-color: #f9f9f9;
  border-radius: 20px;
  /* margin-top: 11rem; */
  /* margin-bottom: 10rem; */
}
.us__left-text {
  width: 70%;
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 2rem 0;
  padding-left: 2rem;
}
.us__left-text h1 {
  width: 110%;
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 36px;
  line-height: 45px;
  margin: 1rem 0;
  color: var(--color-head);
}
.us__left-text p {
  width: 100%;
  color: var(--color-text);
  font-family: var(--font-family2);
  font-weight: 200;
  font-size: 18px;
  line-height: 30px;
  margin-top: 1rem;
}

.us__right-image {
  width: 30%;
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: -6rem;
  margin-bottom: 1rem;
  /* padding: 1rem; */
}
.us__right-image img {
  width: 150%;
  height: 110%;
}
.persons_main_div {
  margin-left: 4rem;
}

.us_section-explore_main  {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.us_section-explore {
  width: 80%;
  /* height: 500px; */
  /* background-color: #3e397c; */
  margin-top: 8%;
}

.heading-explore {
  width: 100%;
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 36px;
  line-height: 45px;
  margin: 1rem 0;
  margin-bottom: 1rem;
  text-align: center;
}
.accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
  transform: scale(.7) !important;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M0 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
}

.accordion {
  display: flex;
   flex-direction: row;
   justify-content: space-between;
   /* background-color: #3e397c; */
}
.accordion2 {
  display: flex;
   flex-direction: row;
   justify-content: flex-start;
   /* background-color: #3e397c; */
}

.accordion-item-main {
  width: 31%;
}
.accordion-item {
  width: 31%;
  border-radius: 0;
  margin-top: 20px;
  border: none;
}
.accordion-button:focus {
  box-shadow: none;
}
.accordion-header {
  border-bottom: 1px solid black;
  /* height: 90px; */
  /* color: #6deb0d !important;
  font-weight: 700 !important; */
}
#headingOne {
  font-family: var(--font-family);
  color: var(--color-head);
  font-weight: 900 !important;
}
.accordion-main {
   margin-top: 5%;
}

.accordion-main1 {
  margin-top: 5%;
}

.accordion-item:first-of-type {
  border-radius: 0;
}

.accordion-item:first-of-type .accordion-button {
  border: none;
  color: #060056;
  font-weight: 600;
  border-radius: 0;
}
.accordion-button {
  color: #060056;
  font-weight: 500;
  font-family: var(--font-family);
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-radius: 0;
}
ul {
  padding-left: 1rem;
}
li {
  color: #000;
}
.accordion-button:not(.collapsed) {
  color: #060056;
  background-color: white;
}
.card-body {
  padding: 15px 0 0 0;
}
.card-body p {
  font-family: var(--font-family2);
  font-size: 15px;
}

@media screen and (max-width: 1350px) {
  .us__section-advance-container {
    flex-direction: column;
    margin: 0;
  }
  .us__section-advance-container {
    gap: 50px;
  }
  .us__possibilitytop {
    width: fit-content;
    height: fit-content;
    flex-direction: column;
    gap: 20px;
  }
  .us__left-text {
    width: 100%;
    /* margin: 2rem; */
    padding: 1rem;
  }
  .us__right-image {
    width: 100%;
  }
  .banner_content_div {
    width: 100%;
    margin: 0;
  }
  .persons_main_div {
    margin-left: 0rem;
  }
}
@media screen and (max-width: 1050px) {
  .us__right-image img {
    width: 60%;
    height: 60%;
  }
  .us__possibility {
    flex-direction: column;
    margin-bottom: 0rem;
  }
  .us__section-intro-content {
    width: 100%;
  }
  .us__possibility-image {
    margin: 1rem 0;
  }

  .us__possibility-content {
    margin-top: 2rem;
  }
  .us__section-logo-container {
    flex-direction: column;
  }
  .us__section-logo-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .us__section-advance-container {
    flex-direction: column;
  }
  .us__section-advance-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 700px) {
  .section__padding1 {
    padding: 2rem 2rem;
  }
  .us__possibility-image img {
    width: 100%;
    height: 100%;
  }
  .us__section-ability-content p {
    font-size: 16px;
  }
  .div2 P {
    width: 100%;
  }
  .us__section-ability-content h1 {
    font-size: 30px;
    margin: 0;
    margin-left: 1rem;
    width: 100%;
  }
  .us__section-logo-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .us__section-advance-container {
    grid-template-columns: repeat(1, 1fr);
  }

  .us__section-intro-content p {
    width: 100%;
    font-size: 16px;
    margin-right: 0;
    text-align: justify;
  }
  .us__section-intro-content h1 {
    width: 100%;
    font-size: 30px;
  }
  .us__right-image p {
    font-size: 16px;
  }

  .us__left-text h1 {
    font-size: 30px;
  }
  .us__section-help {
    background-image: none;
    background-color: #3e397c;
    padding: 3rem 2rem;
    margin-top: 4rem;
  }
  .us__section-ability {
    padding: 1rem;
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
}

@media screen and (max-width: 500px) {
  .us__section-intro_main {
    /* background-color: #060056; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .us__section-intro {
    width: 80%;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    /* background-color: #060056; */
  }
  .us__section-intro-content h1 {
    font-size: 25px;
  }
  .banner_content_div {
    width: 90% !important;
    /* background-color: #060056; */
    margin-left: 15% !important;
  }
  .us__section-intro-content p {
    text-align: left;
  }
}