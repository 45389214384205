.slider_banner {
  /* min-height: 700px; */
  height: 800px;
  width: 100%;
  /* background-color: #000; */
}

.slider_main_div {
  width: 100%;
  height: 800px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.slide_main_content_div {
  width: 100%;
  height: 100%;
  padding: 0 5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.slider_left_div {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.slider_right_div {
  width: 30%;
}

.slider_left_div h1 {
  font-family: var(--font-family);
  font-size: 37px;
  font-weight: 500;
  letter-spacing: -0.04em;
  line-height: 50px;
  text-align: center;
}

.slider_left_div p {
  color: var(--color-text);
  font-family: var(--font-family2);
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  /* margin-top: 0.5rem; */
  text-align: center;
}

.slider_left_div button {
  background: #060056;
  border: none;
  border-radius: 20px;
  color: #fff;
  cursor: pointer;
  flex: 0.6 1;
  font-family: var(--font-family2);
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  min-height: 50px;
  outline: none;
  padding: 0 1rem;
}

.slider4_inner_icon_div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.slider4_inner_icon_div img {
  width: 80px;
  height: 80px;
}


#slider1 {
  background-image: url(../../assets/slider_banner/01.gif);
}

#slider2 {
  background-image: url(../../assets/slider_banner/germanbanner.gif);
}

#slider3 {
  background-image: url(../../assets/slider_banner/03.gif);
}

#slider4 {
  background-image: url(../../assets/slider_banner/04.jpg);
}





@media screen and (max-width: 1400px) {
  .slider_banner {
    height: 600px;
  }

  .slider_main_div {
    height: 600px;
  }
}

@media screen and (max-width: 1000px) {
  .slider_banner {
    height: 550px;
  }

  .slider_main_div {
    height: 500px;
    background-size: contain;
  }

  .slide_main_content_div {
    padding: 0;
    justify-content: center;
  }

  .slider_left_div h1 {
    font-size: 40px;
  }

  .slider_left_div {
    width: 60%;
  }

  .slider_right_div {
    width: 20%;
  }
}

@media screen and (max-width: 600px) {
  .slider_banner {
    height: 600px;
  }

  .slider_main_div {
    height: 600px;
  }

  .slider_main_div {
    background-position: right;
    background-size: cover;
    /* filter: brightness(0.8); */
  }

  .slider_left_div h1 {
    color: #fff;
    font-size: 30px;
  }

  .slider_left_div {
    width: 85%;
    filter: drop-shadow(0px 0px 2px black);
  }

  .slider_right_div {
    width: 0%;
  }

  .slider_left_div p {
    color: #fff;
    font-size: 24px;
  }


  #slider1 {
    background-image: url(../../assets/slider_banner/Phone\ banner\ 01.gif);
  }

  #slider2 {
    background-image: url(../../assets/slider_banner/phonegermn.gif);
  }

  #slider3 {
    background-image: url(../../assets/slider_banner/Phone\ banner\ 03.gif);
  }

  #slider4 {
    background-image: url(../../assets/slider_banner/Phone-banner-04.jpg);
  }

  .slider4_inner_icon_div{
    gap: 30px;
  }

}