.section__padding1 {
  max-width: 1400px;
  padding: 2rem 8rem;
}

.info__container {
  display: flex;
  flex-direction: row;
  padding: 3rem;
  background: #f3f2f2;
  border-radius: 20px;
  margin-bottom: 4rem;
}

.info__container-feature {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}

.info__container-feature {
  margin: 0;
  margin-bottom: 2rem;
}

.img-cont2 {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-right: 2rem;
  padding: 1rem;
}

.img-cont2 img {
  max-width: 25rem;
}

.immi__possibility-content-heading {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 3rem;
}

.immi__possibility-content-heading h1 {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 28px;
  line-height: 45px;
  margin: 1rem 0;
}

/* section three */
.attest-img-cont {
  padding: 4rem;
  justify-content: baseline;
  border: 1px solid var(--color-head);
  border-radius: 20px;
}

.attest__section-logo-container {
  flex: 1;
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 2rem;
  align-items: baseline;
}

.attest__section-help {
  flex: 1;
  display: flex;
  width: 100%;
  color: white;
  flex-direction: column;
  align-items: center;
}

.attest-container-a {
  max-width: 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.attest-container-a p {
  color: black;
  font-family: var(--font-family2);
  font-weight: 200;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  margin-top: 1rem;
}

.attest__section-advance-container {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(4, 220px);
  grid-gap: 2rem;
  margin-top: 3rem;
  justify-content: center;
}

.item5 {
  /* grid-row: 4 / -1; */
  grid-column: 2 / 3;
}

.attest2__section-help {
  background: rgb(60, 54, 141);

  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 80px 80px;
  border-bottom-right-radius: 15px;
  flex: 1;

  padding: 2rem 5rem;
  margin-top: 2rem;
  color: white;

  flex-direction: column;

  background-position: bottom -130px left 0px;
  background-image: url("../../../assets/wavyy.svg");
  background-repeat: no-repeat;
  background-size: contain;

  z-index: 2px;
}

@media screen and (max-width: 1350px) {
  .attest__section-advance-container {
    flex-direction: column;
    margin: 0;
  }

  .attest__section-advance-container {
    grid-template-columns: repeat(3, 1fr);
  }

  .item5 {
    grid-column: auto;
  }

  .info__container {
    flex-direction: column;
  }
}

@media screen and (max-width: 1270px) {
  .attest__main-container {
    overflow-x: hidden;
  }

  .attest__section-logo-container {
    flex-direction: column;
  }

  .attest__section-logo-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 1100px) {

  /* Customizing feature component as per needs */
  .info__container-feature {
    flex-direction: column;
  }

  .immi__possibility-content-heading h1 {
    font-size: 30px;
  }

  /* Customizing feature component as per needs */
  .info__container-feature {
    margin-top: 0.5rem;
  }

  .attest__section-advance-container {
    flex-direction: column;
  }

  .attest__section-advance-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .attest__section-help {
    background-position: bottom -10px left 0px;
  }
}

@media screen and (max-width: 700px) {
  .img-cont2 img {
    width: 100%;
  }

  .info__container {
    margin-bottom: 0;
    padding: 1.5rem;
  }

  .attest__section-logo-container {
    grid-template-columns: repeat(1, 1fr);
  }

  .attest2__section-help {
    padding: 2rem;
  }

  .screen__section-ability-content p {
    font-size: 16px;
  }

  .attest__section-advance-container {
    grid-template-columns: repeat(1, 1fr);
  }

  .screen__section-ability-content h1 {
    font-size: 30px;
  }

  .attest__section-logo-container p {
    font-size: 16px;
  }

  .section__padding1 {
    padding: 2rem 2rem;
  }
}