.blogDetailContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.banner-container {
    width: 80%;
    /* background-color: antiquewhite; */
}

.blogDetailsSubContainer {
    width: 80%;
}
.loadingText {
    text-align: center;
    margin-top: 20px;
    font-size: 30px;
    font-weight: 600;
    color: black;
}
.mainDivblogDetails {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.subDivblogDetailsContent {
    width: 100%;
    /* background-color: rgb(255, 253, 127); */
    /* height: 200px; */
}

.subDivblogDetailsOtherLinks {
    width: 40%;
    /* background-color: aquamarine; */
    /* height: 200px; */
}

.mainImage {
    width: 85%;
    height: 350px;
    border-radius: 10px;
}

.mainHeadingBlogDetails {
    font-family: var(--font-family);
    color: #060056;
    font-size: 34px;
    width: 85%;
    font-weight: 800;
    margin-top: 5%;
}

.mainPara {
    font-family: var(--font-family2);
    color: #000000;
    font-size: 18px;
    font-weight: 500;
    margin-top: 30px;
}

/* .ulList {
    margin-top: 20px;
} */

.listLi {
    font-family: var(--font-family2);
    margin-top: 16px;
    line-height: 20px;
}

.mainDivv {
    width: 100%;
    /* background-color: antiquewhite; */
}

.mainImgDiv {
    width: 100%;
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
}

.linksDiv {
    width: 50%;
}

.mainContainerSubSection {
    margin-top: 40px;
}

.subContent {
    font-family: var(--font-family2);
    margin-top: 20px;
    line-height: 24px;
}

.subDivSection {
    width: 100%;
    /* background-color: #060056; */
    display: flex;
    flex-direction: row;
}

.subDivSectionLeft {
    width: 60%;
    padding: 0px 20px 20px 0px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
}

.subDivSectionRight {
    width: 40%;
}

.subDivSectionRightImg {
    width: 100%;
    height: 250px;
}

.mainHeadingSub {
    font-family: var(--font-family);
    color: #060056;
    font-size: 24px;
    font-weight: 500;
}

@media screen and (min-width: 1500px) {
    .blogDetailsSubContainer {
        width: 60%;
    }
    .banner-container {
        width: 60%;
    }
}

.subSectionDivvBlogDetails {
    width: 85%;
    /* margin-top: 2%; */
    /* background-color: aliceblue;
    height: 600px; */
}

.subSectionDivv p img{
    /* margin-top: 30px; */
}

.textDiv p {
    margin-top: 10px;
    line-height: 23px;
    font-family: var(--font-family2);
    font-size: 16px;
}

.textDiv p strong{
    margin-top: 10px;
    font-family: var(--font-family);
    color: #060056;
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 10px;
}

.textDiv p h3{
    /* margin-top: 20px; */
    font-family: var(--font-family2);
    color: #060056;
    font-size: 20px;
    font-weight: 800;
}

.textDiv  h3{
    /* margin-top: 20px; */
    font-family: var(--font-family2);
    color: #060056;
    font-size: 20px;
    font-weight: 800;
}

.textDiv li {
    font-family: var(--font-family2);
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    /* margin-top: 10px; */
}
.textDiv p h2{
    /* margin-top: 20px; */
    font-family: var(--font-family);
    color: #060056;
    font-size: 20px;
    font-weight: 800;
}

.textDiv p strong{
    margin-top: 10px;
    font-family: var(--font-family);
    color: #060056;
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 10px;
}

.textDivLeft p {
    margin-top: 10px;
    line-height: 23px;
    font-family: var(--font-family2);
    font-size: 16px;
}


.textDivLeft p strong{
    margin-top: 10px;
    font-family: var(--font-family);
    color: #060056;
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 10px;
}


.textDivLeft p h3{
    /* margin-top: 20px; */
    font-family: var(--font-family2);
    color: #060056;
    font-size: 20px;
    font-weight: 800;
}

.textDivLeft h3{
    /* margin-top: 20px; */
    font-family: var(--font-family2);
    color: #060056;
    font-size: 20px;
    font-weight: 800;
}

.textDivLeft li {
    font-family: var(--font-family2);
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    /* margin-top: 10px; */
}
.textDivLeft p h2{
    /* margin-top: 20px; */
    font-family: var(--font-family);
    color: #060056;
    font-size: 20px;
    font-weight: 800;
}

.textDivRight p {
    margin-top: 10px;
    line-height: 23px;
    font-family: var(--font-family2);
    font-size: 16px;
}

.textDivRight p strong{
    margin-top: 10px;
    font-family: var(--font-family);
    color: #060056;
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 10px;
}

.textDivRight p h3{
    /* margin-top: 20px; */
    font-family: var(--font-family2);
    color: #060056;
    font-size: 20px;
    font-weight: 800;
}

.textDivRight h3{
    /* margin-top: 20px; */
    font-family: var(--font-family2);
    color: #060056;
    font-size: 20px;
    font-weight: 800;
}

.textDivRight li {
    font-family: var(--font-family2);
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    /* margin-top: 10px; */
}
.textDivRight p h2{
    /* margin-top: 20px; */
    font-family: var(--font-family);
    color: #060056;
    font-size: 20px;
    font-weight: 800;
}

.img-wrapper {
    /* background-color: rgb(0, 136, 255); */
    width: 100%;
}

.img-wrapper img {
    width: 100%;
    height: 250px;
    border-radius: 10px;
}

.content-section {
    display: flex;
    margin-top: 5%;
    align-items: center;
}

.content-section11 {
    display: flex;
    margin-top: 5%;
    align-items: center;
}

.content-section1 {
    display: flex;
    /* background-color: aliceblue; */
    margin-top: 5%;
    align-items: center;
    flex-direction: row-reverse;
}

.content-section2{
    display: flex;
    /* background-color: aliceblue; */
    margin-top: 2%;
    /* align-items: center; */
    flex-direction: column-reverse;
}

.content-section3{
    display: flex;
    /* background-color: aliceblue; */
    margin-top: 5%;
    /* align-items: center; */
    flex-direction: column;
}

.imgDiv {
    width: 45%;
}

.textDiv {
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-self: center;
    padding-left: 30px;
    /* padding-top: 40px; */
    /* align-items: center; */
}
.textDivLeft {
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-self: center;
    padding-left: 30px;
    /* padding-top: 40px; */
    /* align-items: center; */
}
.textDivRight {
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-self: center;
    padding-right: 30px;
    /* padding-top: 40px; */
    /* align-items: center; */
}

.textDiv5 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-self: center;
    padding-right: 20px;
}

.imgDiv1 {
    width: 50%;
    margin-top: 40px;
} 

.textDiv1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-self: center;
    padding-right: 20px;
    /* margin-top: 30px; */
}

.textDiv2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-self: center;
    padding-right: 20px;
}

.imgDiv2 {
    width: 50%;
}




.textDiv2 p {
    margin-top: 10px;
    line-height: 23px;
    font-family: var(--font-family2);
    font-size: 16px;
}


.textDiv2 p strong{
    margin-top: 10px;
    font-family: var(--font-family2);
    color: #060056;
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 10px;
}

.textDiv2 p h3{
    /* margin-top: 20px; */
    font-family: var(--font-family2);
    color: #060056;
    font-size: 20px;
    font-weight: 800;
}

.textDiv2  h3{
    /* margin-top: 20px; */
    font-family: var(--font-family2);
    color: #060056;
    font-size: 20px;
    font-weight: 800;
}

.textDiv2 li {
    font-family: var(--font-family2);
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    /* margin-top: 10px; */
}
.textDiv2 p h2{
    /* margin-top: 20px; */
    font-family: var(--font-family);
    color: #060056;
    font-size: 20px;
    font-weight: 800;
}



.textDiv1 p {
    margin-top: 10px;
    line-height: 23px;
    font-family: var(--font-family2);
    font-size: 16px;
}


.textDiv1 p strong{
    margin-top: 10px;
    font-family: var(--font-family2);
    color: #060056;
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 10px;
}

.textDiv1 p h3{
    /* margin-top: 20px; */
    font-family: var(--font-family2);
    color: #060056;
    font-size: 20px;
    font-weight: 800;
}

.textDiv1  h3{
    /* margin-top: 20px; */
    font-family: var(--font-family2);
    color: #060056;
    font-size: 20px;
    font-weight: 800;
}

.textDiv1 li {
    font-family: var(--font-family2);
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    /* margin-top: 10px; */
}
.textDiv1 p h2{
    /* margin-top: 20px; */
    font-family: var(--font-family);
    color: #060056;
    font-size: 20px;
    font-weight: 800;
}











.textDiv5 p {
    margin-top: 10px;
    line-height: 23px;
    font-family: var(--font-family2);
    font-size: 16px;
}


.textDiv5 p strong{
    margin-top: 10px;
    font-family: var(--font-family2);
    color: #060056;
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 10px;
}

.textDiv5 p h3{
    /* margin-top: 20px; */
    font-family: var(--font-family2);
    color: #060056;
    font-size: 20px;
    font-weight: 800;
}

.textDiv5  h3{
    /* margin-top: 20px; */
    font-family: var(--font-family2);
    color: #060056;
    font-size: 20px;
    font-weight: 800;
}

.textDiv5 li {
    font-family: var(--font-family2);
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    /* margin-top: 10px; */
}
.textDiv5 p h2{
    /* margin-top: 20px; */
    font-family: var(--font-family);
    color: #060056;
    font-size: 20px;
    font-weight: 800;
}

.textDiv5 h2{
    font-family: var(--font-family);
    color: #060056;
    font-size: 20px;
    font-weight: 800;
}

.bottomBlogList {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* gap: 5px; */
    margin-bottom: 15px;
   width: 80%;
   margin-top: 3%;
}

.view-btn-style {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
  .blogSubSectionDetails {
    width: 30%;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 20px;
    /* margin-top: 2%; */
  }

  .headingRecent {
    font-family: var(--font-family);
    color: #060056;
    font-size: 35px;
    font-weight: 800;
    margin-top: 5%;
  }

@media screen and (max-width: 550px) {
    .mainHeading {
        width: 100%;
        font-size: 20px;
    }
    .imgDiv {
        width: 100%;
    }
    .img-wrapper img {
        height: 230px;
    }
    .content-section {
        width: 100%;
        flex-direction: column;
    }
    .textDiv {
        width: 100%;
        margin-top: 10px;
        padding-left: 0;
    }
    .bottomBlogList {
        flex-wrap: wrap;
    }
    .blogSubSectionDetails {
        width: 100%;
        margin-top: 20px;
        height: 300px;
    }
}