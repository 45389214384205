.section__padding1 {
  max-width: 1400px;
  padding: 2rem 8rem;
}
.canada__main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.canada__possibility {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 5rem;
}
.canada__possibility-image {
  flex: 1;
  display: flex;
  /* justify-content: flex-start;
      align-items: flex-start; */
  /* max-width: 35rem; */
  margin-right: 6rem;
}
.canada__possibility-image img {
  width: 100%;
  height: 100%;
}
.canada__possibility-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.canada__possibility-content h1 {
  width: 100%;
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 34px;
  line-height: 45px;
  margin: 1rem 0;
}

.canada__possibility-content p {
  color: var(--color-text);
  font-family: var(--font-family2);
  font-weight: 200;
  font-size: 18px;
  line-height: 30px;

  margin-bottom: 2rem;
}

/* Section intro */

/* Section Ability */
.canada__section-ability-content {
  margin-left: 5rem;
  display: flex;
  flex-direction: column;
}

.canada__section-ability-content h1 {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 34px;
  line-height: 45px;
  margin: 1rem 0;
  margin-bottom: 2rem;
}
.canada__section-ability-content p {
  color: var(--color-text);
  font-family: var(--font-family2);
  font-weight: 200;
  font-size: 18px;
  line-height: 30px;

  margin-bottom: 2rem;
}

/* section 3 */
.canada__section-helpp {
  /* background: #f9f9f9; */
  background-image: url(../../assets/Group_493.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: 20px;
  flex: 1;
  display: flex;
  padding: 2rem 0rem;
  margin-top: 2rem;
  margin-bottom: 10rem;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.canada__section-introo-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.canada__section-introo-content h1 {
  width: 100%;
  text-align: center;
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 36px;
  line-height: 45px;
  margin: 1rem 0;
  margin-bottom: 2rem;
  color: var(--color-head);
}
.canada__section-introo-content p {
  width: 100%;
  text-align: center;
  color: black;
  font-family: var(--font-family2);
  font-weight: 200;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 2rem;
}
.canada__section-introo-content button {
  width: 267px;
  height: 50px;
  font-family: Archivo;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  color: #060056;
  border-radius: 40px;
  border: 1px solid #060056;
  box-shadow: 0px 0px 1px 0px #060056;
  cursor: pointer;
}

/* new container */
.img-cont {
  width: 160px;
  height: 160px;
  background: linear-gradient(
    126deg,
    rgba(209, 208, 224, 1) 0%,
    rgba(6, 0, 86, 1) 100%
  );
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.canada__section-logo-container {
  width: 100%;
  flex: 1;
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 2rem;
  align-items: baseline;
}
.canada__section-help {
  flex: 1;
  display: flex;
  width: 100%;
  color: white;
  flex-direction: column;
  align-items: center;
}
.canada-container {
  margin-top: 10rem;
}
.canada-container-a {
  max-width: 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.canada-container-a p {
  color: black;
  font-family: var(--font-family2);
  font-weight: 200;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  margin-top: 1rem;
}
.need_immigration_for_uk_main_div {
  padding: 0rem 12rem;
}
.need_immigration_for_uk_inner_div2 {
  width: 40%;
}
.need_immigration_for_uk_inner_div2 button {
  cursor: pointer;
}
.banner_content_div {
  margin: 0;
  width: 70%;
  text-align: justify;
}
.img-conta2 {
  flex: 1 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-right: 2rem;
  padding: 1rem;
}

.img-conta2 img {
  max-width: 17rem;
}
@media screen and (max-width: 1270px) {
  .canada__section-ability-content {
    width: 100%;
    margin: 0%;
  }
  .canada__section-ability-content p {
    width: 100%;
  }
  .canada__section-helpp {
    border-radius: 20px;
    padding: 2rem 2rem;
    margin-bottom: 3rem;
    background-position: top;
    box-shadow: 0px 0px 10px 0px lightgray;
  }
  .need_immigration_for_uk_main_div {
    padding: 4rem 2rem;
  }
  .canada__section-logo-container {
    flex-direction: column;
  }
  .canada__section-logo-container {
    margin-top: 2rem;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 1050px) {
  .canada__possibility {
    flex-direction: column;
    margin-bottom: 0rem;
  }
  .banner_content_div {
    width: 100%;
  }
  .canada__possibility-image {
    margin: 1rem 0;
  }

  .canada__possibility-content {
    margin-top: 2rem;
  }
}
@media screen and (max-width: 700px) {
  .canada-container {
    margin-top: 3rem;
  }
  .img-conta2 {
    justify-content: center;
  }
  .need_immigration_for_uk_inner_div2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .need_immigration_for_uk_inner_div1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .canada__possibility-image img {
    width: 100%;
    height: 100%;
  }
  .canada__section-ability-content p {
    font-size: 16px;
  }
  .canada__section-ability-content h1 {
    font-size: 30px;
  }

  .canada__section-intro-content p {
    font-size: 16px;
  }
  .canada__section-intro-content h1 {
    font-size: 30px;
  }
  .canada__possibility-content p {
    font-size: 16px;
  }
  .canada__section-logo-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .canada__section-introo-content p {
    font-size: 16px;
  }
  .canada__section-introo-content h1 {
    font-size: 30px;
  }
}
