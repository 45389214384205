.scrollTop {
    position: fixed; 
    /* width: 100%; */
    bottom: 20px;
    right: 20px;
    /* left: 42%; */
    align-items: center;
    height: 20px;
    justify-content: center;
    z-index: 100;
    cursor: pointer;
    /* animation: fadeIn 0.3s;
    transition: opacity 0.4s; */
    /* opacity: 0.5; */
    display: none;
    -webkit-animation: animation-scroll-top 0.8s ease-in-out;
            animation: animation-scroll-top 0.8s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    color: #ef0325;
  }
  
  .scrollTop:hover{
    color: #060056;
  }

  @-webkit-keyframes animation-scroll-top {
    0% {
      -webkit-transform: translateY(500%);
              transform: translateY(500%);
    }
  }
  
  @keyframes animation-scroll-top {
    0% {
      -webkit-transform: translateY(500%);
              transform: translateY(500%);
    }
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }

