.main__container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section__padding1 {
  max-width: 1400px;
  padding: 2rem 8rem;
}

.screen__section-ability-content h1 {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 28px;
  line-height: 45px;
  margin: 1rem 0;
  margin-bottom: 2rem;
}

.screen__section-ability-content p {
  color: var(--color-text);
  font-family: var(--font-family2);
  font-weight: 200;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 2rem;
}

.screen__section-logo-container {
  display: grid;
  grid-template-columns: repeat(4, 250px);
  grid-gap: 2rem;

  justify-content: center;
}

.screen-container-a {
  max-width: 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.screen-container-a p {
  color: black;
  font-family: var(--font-family2);
  font-weight: 200;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  margin-top: 1rem;
}

.screen-img-cont {
  padding: 2rem;
  justify-content: baseline;
  background: rgb(209, 208, 224);
  background: linear-gradient(126deg,
      rgb(234, 233, 237) 0%,
      rgb(239, 239, 244) 100%);
  border-radius: 120px;
  box-shadow: 2px 6px 1px #706c9e;
}

.screen-img-cont img {
  width: 70px;
  height: 70px;
}

.screen__section-help {
  text-align: center;
}

/* .item11 {
  grid-column: 3 / 2;
  display: flex;
  margin-left: 6rem;
}
.item12 {
  margin-left: 6rem;
} */
/* .item12 {
  grid-column: 1 / 1;
} */

@media screen and (max-width: 1270px) {
  .screening__main-container {
    overflow-x: hidden;
  }

  .screen__section-logo-container {
    flex-direction: column;
  }

  .screen__section-logo-container {
    /* grid-template-columns: repeat(2, 1fr); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .item11 {
    grid-column: auto;
  }
}

@media screen and (max-width: 700px) {
  .screen__section-ability-content p {
    font-size: 16px;
    margin: 0;
    margin-top: 2rem;
  }

  .screen__section-ability-content h1 {
    font-size: 20px;
    margin: 0;
    line-height: 30px;
  }

  .section__padding1 {
    padding: 2rem 2rem;
  }

  .screen__section-logo-container {
    grid-template-columns: repeat(1, 1fr);
  }
}