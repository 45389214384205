.banner__possibilitytop {
  display: flex;
  flex-direction: row;

  background-color: var(--color-head);
  border-radius: 20px;
}
.banner__left-text {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 5rem;
  padding-left: 5rem;
}
.banner__left-text h1 {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 36px;
  line-height: 45px;
  margin: 1rem 0;
  color: white;
}

.banner__right-image {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-right: 2rem;
  padding: 1rem;
}
.banner__right-image img {
  max-width: 25rem;
}

@media screen and (max-width: 900px) {
  .banner__possibilitytop {
    flex-direction: column;
  }
  .banner__left-text {
    margin: 2rem;
  }
}
@media screen and (max-width: 700px) {
  .banner__right-image p {
    font-size: 16px;
  }
  .banner__left-text {
    padding-left: 0rem;
  }
  .banner__sectiontwo h1 {
    font-size: 30px;
  }
  .banner__left-text h1 {
    font-size: 30px;
  }
  .banner__right-image img {
    width: 100%;
  }
}
