.immi__footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background: #060056; */
  background-image: url("../../assets/fanuunWebFooter.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.immi__social {
  display: flex;
  /* flex-wrap: wrap; */
  /* justify-content: center; */
  align-items: center;
  margin-top: 1rem;
}

.immi__social div {
  margin: 0.3rem;
  cursor: pointer;
  /* display: flex; */
  /* justify-content: space-between; */
}

.immi__footer-links {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  width: 80%;
  text-align: left;
  margin-top: 15%;
  /* margin-top: 15%; */
}

.immi__footer-links div {
  width: 200px;
  /* margin: 1rem; */
}

.immi__footer-links_logo {
  display: flex;
  flex-direction: column;
}

.immi__footer-links_logo img {
  margin-bottom: 1rem;
}

.immi__footer-links_logo p {
  font-family: var(--font-family);
  font-size: 26px;
  line-height: 30px;
  color: rgb(255, 255, 255);
  font-weight: 400;
  text-align: center;
}

.immi__footer-links_logo button {
  background: #060056;
  border-radius: 10px;
  color: white;
  font-style: normal;
  margin-top: 1rem;
  font-size: 18px;
  line-height: 30px;
  padding: 0.5rem 3rem;
  border: none;
  outline: none;
  font-family: var(--font-family2);
  cursor: pointer;
  min-width: 150px;
}

.immi__footer-links_div {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.immi__footer-links_div h4 {
  font-size: 14px;
  line-height: 17px;
  font-family: var(--font-family2);
  color: rgb(255, 255, 255);

  margin-bottom: 0.9rem;
}

.headingMainFooter {
  font-size: 18px;
  line-height: 20px;
  font-family: var(--font-family);
  color: rgb(255, 255, 255);
  /* margin: 0.5rem 0; */
  cursor: pointer;
  font-weight: 600;
}

.paraFooter {
  font-size: 18px;
  line-height: 20px;
  font-family: var(--font-family2);
  color: rgb(255, 255, 255);
  margin: 1.5rem 0rem 0rem 0rem;
  cursor: pointer;
}

.immi__footer-copyright1 {
  margin-top: 2rem;
  /* text-align: center; */
  width: 100%;
  /* background-color: #060056; */
}

.immi__footer-copyright {
  margin-top: 5rem;
  /* text-align: center; */
  width: 80%;
  margin-bottom: 2rem;
  /* background-color: #060056; */
}

.immi__footer-copyright p {
  font-size: 18px;
  font-family: var(--font-family2);
  line-height: 20px;
  color: rgb(255, 255, 255);
}

.form__label {
  font-family: var(--font-family2);
  font-size: 1rem;
  margin-left: 2rem;
  margin-top: 0.7rem;
  display: block;
  transition: all 0.3s;
  transform: translateY(0rem);
}

.form__input {
  font-family: "Roboto", sans-serif;
  color: #333;
  font-size: 1rem;
  margin: 0 auto;
  padding: 0.75rem 1rem;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  border: 1px rgb(154, 151, 151) solid;
  width: 100%;
  display: block;
  margin-top: 2rem;
  transition: all 0.3s;
}

.whatsapp_float img {
  display: flex;
}

.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.box1 {
  background-color: #000;

  opacity: 0.5;
}

@media screen and (max-width: 1000px) {
  .immi__footer {
    background-image: url("../../assets/webfoot.jpg");
    background-size: cover;
    background-repeat: no-repeat;
  }
  .immi__footer-links {
    width: 95%;
    justify-content: flex-start;
  }
  .immi__footer-links div {
    margin-left: 20px;
  }
  .immi__footer-copyright1 {
    margin-left: 0 !important;
  }
  .immi__social {
    margin-left: 0 !important;
  }
}

@media screen and (max-width: 850px) {
  .immi__footer-links p {
    font-size: 18px;
  }
}

@media screen and (max-width: 550px) {
  .immi__footer-links div {
    margin: 1rem 0;
  }

  .immi__footer-links_logo button {
    font-size: 18px;
    line-height: 28px;
  }

  .form__input {
    font-size: 18px;
  }
}

@media screen and (max-width: 550px) {
  .immi__footer {
    width: 100%;
    background-size: contain;
    background-image: url("../../assets/mobfooter.jpg");
    background-size: cover;
  }

  .immi__footer-links {
    margin-top: 5rem;
    width: 90%;
  }

  .immi__footer-links div {
    width: 90%;
  }

  .immi__footer-links_logo img {
    width: 60%;
  }

  .immi__footer-links_logo p {
    text-align: left;
  }

  .immi__footer-copyright {
    margin-top: 27px;
  }
}
