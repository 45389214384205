.mainContainerGlobaLink {
    width: 100%;
    /* background-color: #060056; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bannerContainer {
    height: 45vh;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    /* background-color: red; */
}

.bannerHeading {
    width: 71%;
    font-size: 41px;
    line-height: 52px;
    display: flex;
    font-family: var(--font-family);
    text-shadow: 0 0 10px #060056;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}

.subContainerglobaLink {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.subDivGloba {
    /* background-color: #060056; */
    width: 80%;
}

.introContainer {
    /* height: 26vh; */
    width: 100%;
    margin-top: 5%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    /* background-color: antiquewhite; */
}

.introHeading {
    margin-bottom: 24px;
    /* line-height: 40px; */
    font-size: 32px;
    font-weight: 500;
    text-align: center;
    font-family: var(--font-family)
}

.text1 {
    color: var(--color-head);
    font-weight: 450;
    font-style: italic;
}

.text2 {
    color: var(--color-head);
}

.introText {
    width: 78%;
    font-size: 21px;
    line-height: 32px;
    text-align: center;
    font-family: var(--font-family2);
}

.pDiv {
    /* background-color: #cf0c0c; */
    width: 100%;
    margin-top: 4%;
    /* height: 38vh; */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    /* line-height: 35px; */
    align-self: center;
}

.pDivLeft {
    width: 56%;
    /* height: 38vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: var(--font-family);
}

.pHeading {
    font-size: 32px;
    line-height: 50px;
    color: #000000;
    font-weight: 500;
}

.pSubHeading {
    color: var(--color-head);
}

.pText {
    color: black;
    font-size: 22px;
    margin-top: 22px;
    line-height: 26px;
    align-self: flex-end;
    font-family: var(--font-family2);
}

.pDivRight {
    display: flex;
    align-items: end;
    justify-content: flex-end;
}

.pImg {
    width: 93%;
    height: 240px;
    object-fit: contain;
}

.pImgDiv {
    /* background-color: #cf0c0c; */
    width: 80%;
    display: flex;
    flex-direction: row;
    margin-top: 5%;
}

.subDivImg {
    /* height: 30vh;     */
    display: flex;
    width: 33%;
    align-items: center;
    flex-direction: column;
}

.img {
    width: 80%;
    height: 170px;
    /* height: auto; */
    /* object-fit: contain; */
}

.imgText {
    font-family: var(--font-family);
    color: var(--color-head);
    font-size: 18px;
    width: 80% !important;
    /* background-color: #060056; */
    /* height: 10vh; */
    /* margin-top: 10px !important; */
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
    /* text-align: center; */
    font-weight: 600;
    margin: 0;
}

.bDiv {
    width: 80%;
    /* background-color: #060056; */
    margin-top: 5%;
    height: 370px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    /* line-height: 35px; */
    align-self: center;
    /* background-color: aquamarine; */
}

.bDivLeft {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
    /* background-color: beige; */
}

.bDivRight {
    width: 50%;
    /* height: 38vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: var(--font-family);
    /* background-color: wheat; */
}

.bImg {
    width: 90%;
    height: 32vh;
    object-fit: contain;
}

.bText {
    width: 93%;
    color: black;
    font-size: 22px;
    margin-top: 22px;
    line-height: 26px;
    /* align-self: flex-end; */
    font-family: var(--font-family2);
}

.bImgDiv {
    /* height: 44vh; */
    display: flex;
    width: 80%;
    /* align-items: center; */
    flex-direction: row;
    margin-top: 5%;
}

.paraGeneralGermany {
    font-family: var(--font-family2);
    color: black;
    font-size: 20px;
}



.slickSliderDiv {
    width: 80%;
    margin-top: 5%;
}

.slick-next:before, .slick-prev:before {
    font-size: 20px;
    line-height: 1;
    opacity: .75;
    color: #ff0000;
    /* background: aquamarine; */
}

.imgTextDivv {
    width: 90%;
    background-color: rgba(186, 186, 186, 0.273);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    border-radius: 15px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.26);
    height: 70px;
}

.imgTextImg {
    width: 40px;
    /* background-color: #060056; */
}

@media screen and (min-width: 1550px) {
    .subDivGloba {
        width: 70%;
    }
    .pImgDiv {
        width: 70%;
    }
    .bDiv {
      width: 70%;   
    }
    .bImgDiv {
        width: 70%;
    }
    #process {
        width: 70%;
    }
}

@media screen and (max-width: 1024px) {
    .pDiv {
        flex-direction: column;
    }
    .pDivLeft {
        width: 100%;
    }
    .pDivRight {
        width: 100%;
        justify-content: flex-start;
        margin-top: 20px;
    }
    .pImg {
        width: 50%;
    }
    .bDiv {
        flex-direction: column-reverse;
        height: auto;
    }
    .bDivRight {
        width: 100%;
    }
    .bDivLeft {
        width: 100%;
        justify-content: flex-start;
        margin-top: 2%;
    }
    .bImg {
        width: 50%;
        height: 230px;
    }
    .pImgDiv {
        margin-top: 8%;
    }
    .bImgDiv {
        margin-top: 8%;
    }
}

@media screen and (max-width: 650px) {
    .pImgDiv {
        flex-wrap: wrap;
    }
    .subDivImg {
        width: 50%;
    }
    .bImgDiv {
        flex-wrap: wrap;
    }
    .bannerHeading {
        font-size: 25px;
        line-height: 35px;
        width: 90%;
        margin-bottom: 10px;
    }

    .introText {
        width: 90%;
        font-size: 18px;
    }
}

@media screen and (max-width: 414px) {
    .subDivImg {
        width: 100%;
    }
    .bDivLeft {
        justify-content: center;
    }
    .bImg {
        width: 90%;
        height: 230px;
    }
    .pDivRight {
        justify-content: center;
         }
    .pImg {
        width: 90%;
    }
 }